import { EtaEnum } from "../enums/eta.enum";

export const ETA_OPTIONS: { label: string; value: EtaEnum }[] = [
  {
    label: "ASAP",
    value: EtaEnum.ASAP
  },
  {
    label: "3 months",
    value: EtaEnum.THREE_MONTHS
  },
  {
    label: "6 months",
    value: EtaEnum.SIX_MONTHS
  },
  {
    label: "9 months",
    value: EtaEnum.NINE_MONTHS
  },
  {
    label: "12 months",
    value: EtaEnum.TWELVE_MONTHS
  },
  {
    label: "Not sure",
    value: EtaEnum.NOT_SURE
  }
];
