import React, { FC, useEffect, useMemo } from "react";
import { useState } from "react";
import { Box, IconButton } from "@chakra-ui/react";
import {
  format,
  startOfDay,
  addDays,
  subDays,
  eachDayOfInterval,
  isEqual,
  isAfter,
  isSunday
} from "date-fns";
import { ValuationAppointmentBookingCalendarColumn } from "./ValuationAppointmentBookingCalendarColumn";
import { LeadValuationAppointmentAvailabilityType } from "types/lead-valuation-appointment-availability.type";
import { GrNext, GrPrevious } from "react-icons/gr";

const today = startOfDay(new Date());

interface IValuationAppointmentBookingCalendarProps {
  hours: Date[];
  onChange: (availability: LeadValuationAppointmentAvailabilityType) => void;
}

export const ValuationAppointmentBookingCalendar: FC<IValuationAppointmentBookingCalendarProps> = ({
  hours,
  onChange
}) => {
  const [currentDay, setCurrentDay] = useState<Date>(today);
  const [selectedHours, setSelectedHours] = useState<Date[]>(hours || []);

  const days = useMemo(() => {
    const endDay = addDays(currentDay, 6); // We add one more since we might get sunday
    const daysWithoutSunday = eachDayOfInterval({
      start: currentDay,
      end: endDay
    }).filter(day => !isSunday(day));
    return daysWithoutSunday.slice(0, 5);
  }, [currentDay]);

  const endDay = useMemo(() => {
    return days[days.length - 1];
  }, [days]);

  const startDayMonth = useMemo(() => {
    return format(currentDay, "MMMM");
  }, [currentDay]);

  const endDayMonth = useMemo(() => {
    return format(endDay, "MMMM");
  }, [endDay]);

  const selectHour = (hour: Date) => {
    setSelectedHours([...selectedHours, hour]);
  };

  const deselectHour = (hour: Date) => {
    const index = selectedHours.findIndex(sh => isEqual(sh, hour));
    if (index >= 0) {
      setSelectedHours([
        ...selectedHours.slice(0, index),
        ...selectedHours.slice(index + 1)
      ]);
    }
  };

  const handleNextClick = () => {
    let nextDay = addDays(currentDay, 1);
    if (isSunday(nextDay)) {
      nextDay = addDays(nextDay, 1);
    }
    setCurrentDay(nextDay);
  };

  const handlePreviousClick = () => {
    let prevDay = subDays(currentDay, 1);
    if (isSunday(prevDay)) {
      prevDay = subDays(prevDay, 1);
    }
    setCurrentDay(prevDay);
  };

  const canPreviousClick = useMemo(() => {
    return isAfter(currentDay, today);
  }, [currentDay]);

  useEffect(() => {
    onChange({
      hours: selectedHours
    });
  }, [onChange, selectedHours]);

  return (
    <>
      <Box
        d="flex"
        justifyContent="space-between"
        pt={1}
        pb={1}
        pl={2}
        pr={2}
        alignItems="center"
      >
        <IconButton
          bg={"white"}
          icon={<GrPrevious />}
          onClick={handlePreviousClick}
          aria-label="Previous day"
          isDisabled={!canPreviousClick}
        />
        <Box textAlign="center" fontWeight={"semibold"}>
          {startDayMonth}
          {endDayMonth !== startDayMonth ? ` - ${endDayMonth}` : ""}
        </Box>
        <IconButton
          bg={"white"}
          icon={<GrNext />}
          onClick={handleNextClick}
          aria-label="Next day"
        />
      </Box>
      <Box d="flex">
        {days.map(day => (
          <ValuationAppointmentBookingCalendarColumn
            key={day.getTime()}
            day={day}
            deselectHour={deselectHour}
            selectedHours={selectedHours}
            selectHour={selectHour}
            isLastDay={isEqual(day, endDay)}
          />
        ))}
      </Box>
    </>
  );
};
