import React from "react";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import { CSSReset } from "@chakra-ui/react";
import { FullPageWidget } from "./routes/FullPageWidget";
import { PopupWidgetToggle } from "./routes/PopupWidgetToggle";
import {
  FULL_PAGE_WIDGET_ROUTE,
  HOME_ROUTE,
  POPUP_WIDGET_TOGGLE_ROUTE
} from "./constants/routes";
import { ConfigLoader } from "./widgets/ConfigLoader";

export const App = () => {
  return (
    <>
      <CSSReset />
      <ConfigLoader>
        <Router>
          <Switch>
            <Route
              exact={true}
              path={HOME_ROUTE}
              render={() => <p>It's alive!</p>}
            />
            <Route
              exact={true}
              path={FULL_PAGE_WIDGET_ROUTE}
              component={FullPageWidget}
            />
            <Route
              exact={true}
              path={POPUP_WIDGET_TOGGLE_ROUTE}
              component={PopupWidgetToggle}
            />
          </Switch>
        </Router>
      </ConfigLoader>
    </>
  );
};
