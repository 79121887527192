import { Box } from "@chakra-ui/react";
import { format } from "date-fns";
import React, { FC, useCallback } from "react";

interface IValuationAppointmentBookingCalendarHourCellProps {
  hour: Date;
  selected: boolean;
  deselectHour: (hour: Date) => void;
  selectHour: (hour: Date) => void;
}

export const ValuationAppointmentBookingCalendarHourCell: FC<IValuationAppointmentBookingCalendarHourCellProps> = ({
  hour,
  selected,
  deselectHour,
  selectHour
}) => {
  const onClick = useCallback(
    (hour: Date) => () => {
      if (selected) {
        deselectHour(hour);
      } else {
        selectHour(hour);
      }
    },
    [selected, deselectHour, selectHour]
  );

  return (
    <Box
      key={hour.getTime()}
      borderBottom="1px"
      borderColor="gray.200"
      onClick={onClick(hour)}
      bg={selected ? "primary" : "transparent"}
      pb={1}
      pt={1}
      cursor="pointer"
      color={selected ? "white" : "gray.800"}
      _hover={!selected ? { bg: "gray.200" } : {}}
    >
      {format(hour, "HH:mm")}
    </Box>
  );
};
