import React, { FC, useCallback, useMemo, useState } from "react";
import { FormSchema, getReachableFieldsInOrder } from "@leadpro/forms";
import { NumberedInput } from "../../../FormWidget/components/NumberedInput";
import { FormControl } from "../../../../components/FormControl/FormControl";
import {
  getFormControlPropsFromSchemaItem,
  TFormFromSchemaValues
} from "utils/form-schema.utils";
import { Box, SlideFade, VStack } from "@chakra-ui/react";
import { FormikErrors } from "formik/dist/types";
import { QuestionnaireDynamicFormSubmitSection } from "../QuestionnaireDynamicFormSubmitSection";
import { QuestionnaireDynamicFormSlideshowProgressionSection } from "./QuestionnaireDynamicFormSlideshowProgressionSection";

interface IProps {
  formSchema: FormSchema;
  formValues: TFormFromSchemaValues;
  errors: FormikErrors<TFormFromSchemaValues>;
  isSubmitting: boolean;
}

export const QuestionnaireDynamicFormSlideshowElements: FC<IProps> = ({
  formSchema,
  formValues,
  errors,
  isSubmitting
}) => {
  const [currentQuestionIndex, setCurrentQuestionIndex] = useState<number>(0);
  const reachableFieldsInOrder = useMemo(
    () => getReachableFieldsInOrder(formSchema, formValues),
    [formSchema, formValues]
  );

  const currentFieldKey = reachableFieldsInOrder[currentQuestionIndex];

  const goForward = useCallback(() => {
    setCurrentQuestionIndex(prev =>
      prev !== reachableFieldsInOrder.length ? prev + 1 : prev
    );
  }, [reachableFieldsInOrder]);

  const isForwardDisabled = useMemo(() => {
    const currentFieldKey = reachableFieldsInOrder[currentQuestionIndex];
    const schemaItem = formSchema.fields[currentFieldKey];
    if (!currentFieldKey || !schemaItem) return false;

    return (
      !!errors[currentFieldKey] ||
      (!!schemaItem.required && !formValues[currentFieldKey])
    );
  }, [
    formValues,
    currentQuestionIndex,
    errors,
    formSchema.fields,
    reachableFieldsInOrder
  ]);

  return (
    <VStack spacing={10} width={"100%"} height={"100%"} alignItems={"center"}>
      {currentQuestionIndex < reachableFieldsInOrder.length ? (
        <>
          <Box width={"100%"}>
            <SlideFade key={currentFieldKey} in={true} offsetX={1000}>
              <NumberedInput orderNumber={currentQuestionIndex + 1}>
                <FormControl
                  {...getFormControlPropsFromSchemaItem(
                    formSchema,
                    currentFieldKey
                  )}
                />
              </NumberedInput>
            </SlideFade>
          </Box>
          <Box width={"100%"} textAlign={"right"}>
            <QuestionnaireDynamicFormSlideshowProgressionSection
              isSubmitting={isSubmitting}
              formValues={formValues}
              goForward={goForward}
              isForwardDisabled={isForwardDisabled}
              currentQuestionIndex={currentQuestionIndex}
              totalQuestionsCount={reachableFieldsInOrder.length - 1}
            />
          </Box>
        </>
      ) : (
        <QuestionnaireDynamicFormSubmitSection
          isSubmitting={isSubmitting}
          formValues={formValues}
        />
      )}
    </VStack>
  );
};
