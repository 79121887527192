import { Box, Text } from "@chakra-ui/react";
import { Alert } from "../../../../../components/Alert/Alert";
import React from "react";

export const ValuationReportAppointmentAvailabilityMessage = () => {
  return (
    <Box px={5}>
      <Alert
        type={"info"}
        message={
          <Text fontSize={["sm", "base"]} maxW="3xl" mx="auto">
            You have sent a valuation appointment request, and a member of our
            team will be in touch to confirm your valuation appointment shortly.
          </Text>
        }
      />
    </Box>
  );
};
