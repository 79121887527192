import { Box, useMediaQuery } from "@chakra-ui/react";
import { Wizard } from "components/Wizard/Wizard";
import { FormFromSchema } from "./components/FormFromSchema";
import { FormWidgetLeftBar } from "./components/FormWidgetLeftBar";
import { FormWidgetFinalStep } from "./components/FormWidgetFinalStep";
import React from "react";
import { useAppContext } from "utils/react-hooks/useAppContext";

export const FormWidget = () => {
  const [isWiderThan680] = useMediaQuery("(min-width: 680px)");
  const [isWiderThan1020] = useMediaQuery("(min-width: 1020px)");
  const {
    config: { photoUrl }
  } = useAppContext();

  return (
    <Box
      display={"flex"}
      flexDirection={isWiderThan680 ? "row" : "column"}
      justifyContent={"flex-start"}
      height={"100%"}
      width={"100%"}
      overflow={"hidden"}
    >
      <FormWidgetLeftBar />
      <Box
        flexGrow={1}
        bg={"#FFF"}
        display={"flex"}
        overflow={isWiderThan680 ? "inherit" : "auto"}
      >
        <Box
          overflow={isWiderThan680 ? "auto" : "inherit"}
          flexGrow={1}
          maxWidth={"940px"}
        >
          <Box py={20} px={4} alignItems={"center"} justifyContent={"center"}>
            <Wizard>
              {nextPage => <FormFromSchema onSubmit={nextPage} />}
              {nextPage => <FormWidgetFinalStep />}
            </Wizard>
          </Box>
        </Box>
        {!!photoUrl && isWiderThan1020 && (
          <Box
            flexGrow={1}
            backgroundImage={`url(${photoUrl})`}
            backgroundSize="cover"
            backgroundPosition="center"
          />
        )}
      </Box>
    </Box>
  );
};
