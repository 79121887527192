import { colors } from "./colors";
import { typography } from "./typography";
import { extendTheme } from "@chakra-ui/react";

const customTheme = extendTheme({
  ...colors,
  ...typography,
  components: {
    inputs: {
      border: "#e2e8f1",
      focused: {
        border: "#a1aec1",
        boxShadow: "0 0 0 3px rgba(00,00,00,0.1)"
      }
    },
    Link: {
      baseStyle: {
        color: "link"
      }
    }
  }
});

export default customTheme;
