export const colors = {
  colors: {
    primary: "#0e143c",
    error: "#D6263B",
    link: "#1B223E",

    leadpro: {
      50: "#FAFBFF", // background alternative
      100: "#F2F4FA",
      200: "#E4E7F2", // borders
      300: "#BEC5DB",
      400: "#8D96B2", // ultra light text
      500: "#566081", // secondary text
      600: "#3C68AA",
      700: "#383F55" //  primary text
    }
  }
};
