import { Box } from "@chakra-ui/react";
import React, { FC } from "react";

interface IValuationReportModeTabsItemProps {
  children: React.ReactNode;
  isActive: boolean;
  onClick: () => void;
}

export const ValuationReportModeTabsItem: FC<IValuationReportModeTabsItemProps> = ({
  children,
  isActive,
  onClick
}) => {
  return (
    <Box
      px={4}
      py={2}
      width="50%"
      fontSize="sm"
      textAlign="center"
      rounded="full"
      bg={isActive ? "white" : "transparent"}
      lineHeight="none"
      cursor="pointer"
      onClick={onClick}
    >
      {children}
    </Box>
  );
};
