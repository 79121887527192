import React, { FC } from "react";
import {
  Box,
  FormLabel as CFormLabel,
  FormLabelProps as CFormLabelProps
} from "@chakra-ui/react";

export const FormLabel: FC<CFormLabelProps> = ({ children, ...rest }) => {
  return (
    <CFormLabel
      mb={2}
      textTransform="capitalize"
      lineHeight="normal"
      fontSize="md"
      color="leadpro.700"
      fontWeight="medium"
      requiredIndicator={
        <Box as={"span"} px={1} fontSize={"md"} color={"leadpro.400"}>
          *
        </Box>
      }
      {...rest}
    >
      {children}
    </CFormLabel>
  );
};
