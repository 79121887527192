import { IVTLeadTypes } from "types/ivt.type";
import { LeadTypes } from "@leadpro/forms";

export const LEAD_KIND_OPTIONS: { label: string; value: IVTLeadTypes }[] = [
  {
    label: "Selling my property",
    value: LeadTypes.Vendor
  },
  {
    label: "Letting my property",
    value: LeadTypes.Landlord
  }
];
