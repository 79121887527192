import { useAppContext } from "../../../../utils/react-hooks/useAppContext";
import { QuestionnaireDynamicFormSubmitSection } from "../QuestionnaireDynamicFormSubmitSection";
import React, { FC } from "react";
import { Button } from "@chakra-ui/react";
import { TFormFromSchemaValues } from "utils/form-schema.utils";

interface IProps {
  currentQuestionIndex: number;
  totalQuestionsCount: number;
  isSubmitting: boolean;
  formValues: TFormFromSchemaValues;
  goForward: () => void;
  isForwardDisabled: boolean;
}

export const QuestionnaireDynamicFormSlideshowProgressionSection: FC<IProps> = ({
  currentQuestionIndex,
  totalQuestionsCount,
  isSubmitting,
  formValues,
  goForward,
  isForwardDisabled
}) => {
  const {
    config: { gdprCheckboxEnabled }
  } = useAppContext();

  if (!gdprCheckboxEnabled && currentQuestionIndex === totalQuestionsCount) {
    return (
      <QuestionnaireDynamicFormSubmitSection
        isSubmitting={isSubmitting}
        formValues={formValues}
      />
    );
  }

  return (
    <Button onClick={goForward} isDisabled={isForwardDisabled}>
      OK
    </Button>
  );
};
