import { Box, Heading } from "@chakra-ui/react";
import { Column } from "components/Grid/Column";
import { Grid } from "components/Grid/Grid";
import React, { FC, useEffect } from "react";
import { ValuationReportComparableCard } from "./ValuationReportComparableCard/ValuationReportComparableCard";
import { useAppContext } from "utils/react-hooks/useAppContext";
import { ReportModeToLeadTypeMap } from "constants/lead-type-to-report-mode-map";
import { getLeadComparables } from "api/ivt-lead.api";

interface IValuationReportComparablesProps {
  leadId: number;
}

export const ValuationReportComparables: FC<IValuationReportComparablesProps> = ({
  leadId
}) => {
  const {
    config: { appId },
    ivtStore: { comparables, valuationReportMode, setComparables }
  } = useAppContext();

  const properties =
    comparables?.[ReportModeToLeadTypeMap[valuationReportMode]];

  useEffect(() => {
    const fetchData = async () => {
      try {
        if (!!properties) return;

        const { data } = await getLeadComparables(
          appId,
          leadId,
          ReportModeToLeadTypeMap[valuationReportMode]
        );
        setComparables({
          [ReportModeToLeadTypeMap[valuationReportMode]]: data
        });
      } catch (error) {}
    };

    fetchData();
  }, [appId, leadId, valuationReportMode, properties, setComparables]);

  if (!properties?.length) return null;

  return (
    <Box>
      <Heading
        mb={6}
        textAlign="left"
        lineHeight="normal"
        fontSize="2xl"
        mt={8}
        fontWeight={600}
      >
        Comparables
      </Heading>
      <Grid columns={[1, 2, 3, 3]} spacing={[2, 2, 4, 4]}>
        {properties &&
          properties.map((property, index) => (
            <Column key={index}>
              <ValuationReportComparableCard
                key={index}
                property={property}
                type={ReportModeToLeadTypeMap[valuationReportMode]}
              />
            </Column>
          ))}
      </Grid>
    </Box>
  );
};
