import { ValuationReportModeEnum } from "enums/valuation-report-mode.enum";
import { IVTLeadTypes } from "types/ivt.type";
import { LeadTypes } from "@leadpro/forms";

export const LeadTypeToReportModeMap: {
  [key in IVTLeadTypes]: ValuationReportModeEnum;
} = {
  [LeadTypes.Vendor]: ValuationReportModeEnum.SALE,
  [LeadTypes.Landlord]: ValuationReportModeEnum.LETTING
};

export const ReportModeToLeadTypeMap: {
  [key in ValuationReportModeEnum]: IVTLeadTypes;
} = {
  [ValuationReportModeEnum.SALE]: LeadTypes.Vendor,
  [ValuationReportModeEnum.LETTING]: LeadTypes.Landlord
};
