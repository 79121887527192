import React, { FC } from "react";
import { Box, Text } from "@chakra-ui/react";
import { IconType } from "react-icons";

interface IValuationReportComparableCardAttributeProps {
  icon: IconType;
  label: string;
}

export const ValuationReportComparableCardAttribute: FC<IValuationReportComparableCardAttributeProps> = ({
  icon,
  label,
  ...rest
}) => {
  return (
    <Box display="flex" alignItems="center" {...rest}>
      <Box mr={2}>
        <Box
          w={6}
          h={6}
          rounded={"md"}
          display="flex"
          alignItems="center"
          justifyContent="center"
          fontSize="md"
          bg="gray.100"
        >
          {icon && React.createElement(icon)}
        </Box>
      </Box>
      <Text fontSize={"xs"} color={"gray.500"}>
        {label}
      </Text>
    </Box>
  );
};
