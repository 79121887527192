import React, { CSSProperties } from "react";
import { ClearIndicatorProps as ReactSelectClearIndicatorProps } from "react-select/dist/declarations/src/components/indicators";

export function SelectClearIndicator<T>(
  props: ReactSelectClearIndicatorProps<T>
) {
  const {
    getStyles,
    innerProps: { ref, ...restInnerProps }
  } = props;

  return (
    <div
      {...restInnerProps}
      ref={ref}
      style={getStyles("clearIndicator", props) as CSSProperties}
    >
      <div>Clear</div>
    </div>
  );
}
