import React, { FC, useState } from "react";
import { Box, Image } from "@chakra-ui/react";
import { ValuationReportComparableCardImageThumbs } from "./ValuationReportComparableCardThumbs";
import { PropertyDataType } from "types/property-data.type";

interface IValuationReportComparableCardImagesProps {
  property: PropertyDataType;
}

export const ValuationReportComparableCardImages: FC<IValuationReportComparableCardImagesProps> = ({
  property
}) => {
  const { get_agent_num_photos, get_agent_id } = property;

  const [activePhoto, setActivePhoto] = useState(0);

  const handleSetActivePhoto = (index: number) => {
    setActivePhoto(index);
  };

  return (
    <Box width={"100%"} paddingTop={"80%"} position={"relative"}>
      <Box position={"absolute"} top={0} left={0} bottom={0} right={0}>
        <Image
          bg="gray.100"
          width={"100%"}
          height={"100%"}
          objectFit={"cover"}
          src={`https://www.getagent.co.uk/valuation/property/${get_agent_id}/photo/${activePhoto}`}
          fallbackSrc={"/images/empty-image.png"}
          alt=""
        />
        {get_agent_num_photos > 0 && (
          <ValuationReportComparableCardImageThumbs
            property={property}
            activePhoto={activePhoto}
            handleSetActivePhoto={handleSetActivePhoto}
          />
        )}
      </Box>
    </Box>
  );
};
