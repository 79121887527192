import React, { FC, useMemo } from "react";
import { Box } from "@chakra-ui/react";

type GridContextData = {
  columns: number[];
  spacing: number[];
};

export const GridContext = React.createContext<GridContextData>({
  columns: [],
  spacing: []
});

interface IGridProps extends GridContextData {
  children: React.ReactNode;
}

export const Grid: FC<IGridProps> = ({
  columns = [12],
  spacing = [4],
  children
}) => {
  const margin = useMemo(() => {
    return Array.isArray(spacing) ? [...spacing] : [spacing];
  }, [spacing]);

  return (
    <GridContext.Provider
      value={{
        columns: columns,
        spacing: spacing
      }}
    >
      <Box display="flex" mx={margin.map(x => x * -1)} flexWrap="wrap">
        {children}
      </Box>
    </GridContext.Provider>
  );
};
