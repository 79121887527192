import { Box, BoxProps, HStack } from "@chakra-ui/react";
import { FormControl } from "../../components/FormControl/FormControl";
import { InputEnum } from "../../enums/input.enum";
import { FormLabel } from "../../components/FormLabel/FormLabel";
import React, { FC } from "react";
import { useAppContext } from "utils/react-hooks/useAppContext";

interface IProps extends BoxProps {}

export const GDPRForm: FC<IProps> = props => {
  const {
    config: { gdprCheckboxDescription, gdprCheckboxEnabled, gdprCheckboxLabel }
  } = useAppContext();

  if (!gdprCheckboxEnabled) return null;

  return (
    <HStack spacing={4} {...props}>
      <Box flexGrow={0}>
        <FormControl name={"gdprCheckbox"} type={InputEnum.SWITCH} />
      </Box>
      <Box flexGrow={1}>
        <FormLabel htmlFor={"gdprCheckbox"} fontSize={"md"} mb={0}>
          {gdprCheckboxLabel}
        </FormLabel>
        {gdprCheckboxDescription && (
          <Box color={"leadpro.400"} fontSize={"sm"}>
            {gdprCheckboxDescription}
          </Box>
        )}
      </Box>
    </HStack>
  );
};
