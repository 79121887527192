import React, { useEffect, useState } from "react";
import { Box } from "@chakra-ui/react";
import { useAppContext } from "utils/react-hooks/useAppContext";
import { WidgetEventsEnum } from "enums/widget-events.enum";

// TODO NENAD: NEEDS TO BE SPLIT BETWEEN WIDGETS
export const PopupWidgetToggle = () => {
  const {
    config: { color }
  } = useAppContext();
  const [isOpen, setIsOpen] = useState(false);
  const onClick = () => setIsOpen(!isOpen);

  useEffect(() => {
    window.parent.postMessage(
      { type: WidgetEventsEnum.WIDGET_POPUP_TOGGLED, message: isOpen },
      "*"
    );
  }, [isOpen]);

  return (
    <Box
      onClick={onClick}
      color="white"
      px={3}
      py={3}
      textAlign="center"
      fontWeight="bold"
      lineHeight="none"
      transition="all .25s"
      cursor="pointer"
      style={{ background: color || "#f5f5f5" }}
    >
      Free online valuation
    </Box>
  );
};
