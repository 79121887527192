import React, { FC } from "react";
import {
  Alert as CAlert,
  AlertIcon,
  AlertProps as CAlertProps
} from "@chakra-ui/react";
import { AlertStatus } from "@chakra-ui/alert/src/alert";

interface IAlertProps extends CAlertProps {
  type: AlertStatus;
  message: React.ReactNode;
}

export const Alert: FC<IAlertProps> = ({ type, message, ...rest }) => {
  return (
    <CAlert status={type} p={6} {...rest}>
      <AlertIcon />
      {message}
    </CAlert>
  );
};
