import axios from "axios";
import runtimeEnv from "@mars/heroku-js-runtime-env";
const env = runtimeEnv();

export const createFormResponse = async (
  appId: string,
  data: Partial<any>,
  reCaptchaToken?: string
): Promise<void> => {
  return await axios.post(
    `${env.REACT_APP_NEW_API_BASE_URL}/forms/responses`,
    data,
    {
      headers: {
        Authorization: appId,
        recaptchaToken: reCaptchaToken
      }
    }
  );
};
