import React from "react";
import { Box } from "@chakra-ui/react";
import { useAppContext } from "utils/react-hooks/useAppContext";
import { WidgetsEnum } from "../../enums/widgets.enum";
import { PropertyValuationWidget } from "../../widgets/PropertyValuationWidget/PropertyValuationWidget";
import { FormWidget } from "../../widgets/FormWidget/FormWidget";
import { QuestionnaireWidget } from "../../widgets/QuestionnaireWidget/QuestionnaireWidget";

export const FullPageWidget = () => {
  const {
    config: { photoUrl, pageType }
  } = useAppContext();
  return (
    <Box
      backgroundImage={`url(${photoUrl})`}
      height="100vh"
      backgroundSize="cover"
      backgroundPosition="center"
      overflow={"auto"}
    >
      {pageType === WidgetsEnum.INSTANT_VALUATION_TOOL && (
        <PropertyValuationWidget />
      )}
      {pageType === WidgetsEnum.FORM && <FormWidget />}
      {pageType === WidgetsEnum.QUESTIONNAIRE && <QuestionnaireWidget />}
    </Box>
  );
};
