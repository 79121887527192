import React from "react";
import { Box, Image } from "@chakra-ui/react";
import { useAppContext } from "utils/react-hooks/useAppContext";

export const ValuationReportLogo = () => {
  const {
    config: { logoUrl }
  } = useAppContext();

  return (
    <Box
      bg="white"
      shadow="sm"
      w={[32, 48]}
      h={[24, 32]}
      rounded="md"
      mt={[-12, -16]}
      mx="auto"
      mb={6}
      display="flex"
      alignItems="center"
      justifyContent="Center"
      zIndex={3}
      position="relative"
    >
      <Image src={logoUrl} maxW="60%" />
    </Box>
  );
};
