import React from "react";
import { Box } from "@chakra-ui/react";
import { ValuationReportPriceEstimationDot } from "./ValuationReportPriceEstimationDot";

export const ValuationReportPriceEstimationLine = () => {
  return (
    <Box
      position="relative"
      borderTop="1px solid"
      borderColor="gray.200"
      padding={0}
      mb={3}
    >
      <Box mt={-2} display="flex">
        <ValuationReportPriceEstimationDot justifyContent="flex-start" />
        <ValuationReportPriceEstimationDot size="md" justifyContent="center" />
        <ValuationReportPriceEstimationDot justifyContent="flex-end" />
      </Box>
    </Box>
  );
};
