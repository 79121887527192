import React, { useMemo, useState } from "react";
import { Box } from "@chakra-ui/react";
import { FormikHelpers } from "formik/dist/types";

interface IFormWizardProps<T> {
  style: object;
  onSubmit: (values: T, actions: FormikHelpers<T>) => Promise<void>;
  onFirstStepComplete?: (values: T) => Promise<void>;
  children: Array<
    (props: {
      handleSubmit: (newValues: T, actions: FormikHelpers<T>) => void;
      values: Partial<T>;
    }) => React.ReactNode
  >;
}

export function FormWizard<T>({
  style,
  onSubmit,
  onFirstStepComplete,
  children
}: IFormWizardProps<T>) {
  const [page, setPage] = useState(0);
  const [values, setValues] = useState<Partial<T>>({});
  const activePage = useMemo(() => children[page], [children, page]);

  const nextPage = (newValues: T) => {
    setPage(Math.min(page + 1, children.length - 1));
    setValues(newValues);
  };

  const handleSubmit = async (newValues: T, actions: FormikHelpers<T>) => {
    const isFirstPage = page === 0;
    const isLastPage = page === children.length - 1;

    try {
      if (isLastPage) {
        return onSubmit(newValues, actions);
      } else {
        if (isFirstPage && !!onFirstStepComplete) {
          await onFirstStepComplete(newValues);
        }

        actions.setTouched({});
        actions.setSubmitting(false);
        nextPage(newValues);
      }
    } catch (error) {}
  };

  return <Box style={style}>{activePage({ handleSubmit, values })}</Box>;
}
