import React, { FC, useCallback, useRef } from "react";
import ReCAPTCHA from "react-google-recaptcha";
import runtimeEnv from "@mars/heroku-js-runtime-env";

const env = runtimeEnv();

interface IProps {
  children: (
    getReCaptchaToken: () => Promise<string | undefined>
  ) => React.ReactNode;
}

export const ReCaptchaGuard: FC<IProps> = ({ children }) => {
  const reCaptchaV2Ref = useRef<ReCAPTCHA>(null);

  const getReCaptchaToken = useCallback(async () => {
    try {
      let reCaptchaToken: string | undefined;
      if (reCaptchaV2Ref.current) {
        reCaptchaToken = (await reCaptchaV2Ref.current.executeAsync()) || "";
        reCaptchaV2Ref.current.reset();
      }

      return reCaptchaToken;
    } catch (e) {}
  }, []);

  if (typeof children !== "function")
    throw new Error("Child needs to be a function!");

  return (
    <>
      {children(getReCaptchaToken)}
      <ReCAPTCHA
        sitekey={env.REACT_APP_RECAPTCHA_SITEKEY}
        size={"invisible"}
        ref={reCaptchaV2Ref}
      />
    </>
  );
};
