import {
  Box,
  Center,
  Spinner,
  useMediaQuery,
  useToast,
  UseToastOptions
} from "@chakra-ui/react";
import { useAppContext } from "utils/react-hooks/useAppContext";
import { FormWidgetLeftBar } from "../FormWidget/components/FormWidgetLeftBar";
import { Wizard } from "../../components/Wizard/Wizard";
import React, { useEffect, useState } from "react";
import { QuestionnaireFormStep } from "./components/QuestionnaireFormStep";
import { QuestionnaireWidgetFinalStep } from "./components/QuestionnaireWidgetFinalStep";
import { AlertStatusEnum } from "enums/alert-status.enum";
import { ApiRequestStatusEnum } from "enums/api-request-status.enum";
import { getQuestionnaireResponseState } from "api/questionnaire-response.api";
import { ErrorMessage } from "components/ErrorMessage/ErrorMessage";
import { QuestionnaireExistingResponseMessage } from "./components/QuestionnaireExistingResponseMessage";

export const QuestionnaireWidget = () => {
  const [isWiderThan680] = useMediaQuery("(min-width: 680px)");
  const [isWiderThan1020] = useMediaQuery("(min-width: 1020px)");
  const {
    config: {
      photoUrl,
      pageQuestionnaireConfig: { isSlideshowModeEnabled },
      color,
      appId
    },
    questionnaireStore: { questionnairePreviewType, leadPublicUUID }
  } = useAppContext();

  const [loadingStatus, setLoadingStatus] = useState(ApiRequestStatusEnum.NONE);
  const [existingResponse, setExistingResponse] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");

  const toast = useToast();

  useEffect(() => {
    const fetchData = async () => {
      if (!leadPublicUUID) {
        setLoadingStatus(ApiRequestStatusEnum.SUCCESS);
        return;
      }

      try {
        setLoadingStatus(ApiRequestStatusEnum.PENDING);
        const responseObject = await getQuestionnaireResponseState(
          appId,
          leadPublicUUID
        );
        setExistingResponse(responseObject.hasResponse);
        setLoadingStatus(ApiRequestStatusEnum.SUCCESS);
      } catch (error) {
        setErrorMessage(error.message);
        setLoadingStatus(ApiRequestStatusEnum.ERROR);
      }
    };

    fetchData();
  }, [leadPublicUUID, appId]);

  useEffect(() => {
    if (questionnairePreviewType) {
      const toastOptions: UseToastOptions = {
        title: (
          <Box width={"100%"} textAlign={"center"}>
            Questionnaire Preview
          </Box>
        ),
        description: (
          <Box textAlign={"center"}>
            <Box>You are currently in preview mode.</Box>
            <Box>
              Questionnaire type: <b>{questionnairePreviewType}</b>
            </Box>
          </Box>
        ),
        status: AlertStatusEnum.INFO,
        variant: "subtle",
        duration: null,
        isClosable: true
      };

      toast(toastOptions);

      return () => {
        toast.closeAll();
      };
    }
  }, [questionnairePreviewType, toast]);

  return (
    <Box
      display={"flex"}
      flexDirection={isWiderThan680 ? "row" : "column"}
      justifyContent={"flex-start"}
      height={"100%"}
      width={"100%"}
      overflow={"hidden"}
    >
      <FormWidgetLeftBar />
      <Box
        flexGrow={1}
        bg={"#FFF"}
        display={"flex"}
        overflow={isWiderThan680 ? "inherit" : "auto"}
      >
        <Box
          overflow={isWiderThan680 ? "auto" : "inherit"}
          flexGrow={1}
          maxWidth={"940px"}
          position={"relative"}
        >
          <Box
            py={20}
            px={4}
            alignItems={"center"}
            justifyContent={"center"}
            height={isSlideshowModeEnabled ? "100%" : "unset"}
          >
            {(loadingStatus === ApiRequestStatusEnum.PENDING ||
              loadingStatus === ApiRequestStatusEnum.ERROR) && (
              <Center
                width={"100%"}
                height={"100%"}
                position={"absolute"}
                top={0}
                left={0}
              >
                {loadingStatus === ApiRequestStatusEnum.PENDING && (
                  <Spinner size={"xl"} color={color || undefined} />
                )}
                {loadingStatus === ApiRequestStatusEnum.ERROR && (
                  <ErrorMessage>{errorMessage}</ErrorMessage>
                )}
              </Center>
            )}
            {loadingStatus === ApiRequestStatusEnum.SUCCESS && (
              <>
                {existingResponse ? (
                  <QuestionnaireExistingResponseMessage />
                ) : (
                  <Wizard>
                    {nextPage => <QuestionnaireFormStep onSubmit={nextPage} />}
                    {nextPage => <QuestionnaireWidgetFinalStep />}
                  </Wizard>
                )}
              </>
            )}
          </Box>
        </Box>
        {!!photoUrl && isWiderThan1020 && (
          <Box
            flexGrow={1}
            backgroundImage={`url(${photoUrl})`}
            backgroundSize="cover"
            backgroundPosition="center"
          />
        )}
      </Box>
    </Box>
  );
};
