import { useTheme } from "@chakra-ui/react";
import React from "react";
import ReactSelect from "react-select";
import ReactSelectCreatable from "react-select/creatable";
import { SelectClearIndicator } from "./SelectClearIndicator";
import { Props as ReactSelectProps } from "react-select/dist/declarations/src/Select";
import { GroupBase as ReactSelectGroupBase } from "react-select/dist/declarations/src/types";
import { SelectOptionType } from "types/select-option.type";

interface IProps<T>
  extends ReactSelectProps<
    SelectOptionType<T>,
    boolean,
    ReactSelectGroupBase<SelectOptionType<T>>
  > {
  isCreatable?: boolean;
}
export function Select<T>(props: IProps<T>) {
  const { colors, components, shadows } = useTheme();
  const { value, isCreatable, ...rest } = props;

  const Component = isCreatable ? ReactSelectCreatable : ReactSelect;

  return (
    <Component
      {...rest}
      components={{ ClearIndicator: SelectClearIndicator }}
      styles={{
        clearIndicator: (base, state) => ({
          ...base,
          cursor: "pointer",
          color: state.isFocused ? "gray" : "black"
        }),
        option: (base, state) => ({
          ...base,
          cursor: "pointer",
          color: state.isSelected ? colors.gray[500] : colors.gray[500],
          backgroundColor: state.isSelected ? colors.gray[200] : "white",
          padding: "10px",
          lineHeight: 1,
          "&:active": {
            backgroundColor: "gray.100"
          },
          "&:hover": {
            backgroundColor: state.isSelected
              ? colors.gray[200]
              : colors.gray[100]
          }
        }),
        // container: base => ({
        //   ...base
        // }),
        valueContainer: base => ({
          ...base,
          padding: 0,
          color: "red"
        }),
        indicatorSeparator: base => ({
          ...base,
          backgroundColor: colors.gray[200]
        }),
        placeholder: (base, state) => ({
          ...base,
          color: colors.gray[400]
        }),
        menu: () => ({
          border: "1px solid",
          borderColor: colors.gray[300],
          position: "absolute",
          borderRadius: "3px",
          width: "100%",
          backgroundColor: "#fff",
          boxShadow: shadows.lg,
          zIndex: 99999
        }),
        menuList: base => ({
          ...base,
          maxHeight: "170px !important"
        }),
        control: (base, state) => ({
          ...base,
          borderColor: state.isFocused
            ? components.inputs.focused.border
            : components.inputs.border,
          width: "100%",
          cursor: "pointer",
          paddingLeft: "12px",
          color: "red",
          height: "45px",
          boxShadow: state.isFocused
            ? components.inputs.focused.boxShadow
            : "none",
          "&:hover": {},
          "&:focus": {
            borderColor: components.inputs.focused.border
          }
        }),
        singleValue: (base, state) => {
          const opacity = state.isDisabled ? 0.5 : 1;
          const transition = "opacity 300ms";
          const color = colors.gray[600];
          return {
            ...base,
            opacity,
            color,
            transition
          };
        }
      }}
    />
  );
}
