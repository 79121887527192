import {
  TCreatedLead,
  TIncompleteLead,
  TLeadToBeCreated
} from "types/lead.type";
import axios from "axios";
import { LeadValuationAppointmentAvailabilityType } from "types/lead-valuation-appointment-availability.type";
import runtimeEnv from "@mars/heroku-js-runtime-env";
import { PropertyDataType } from "types/property-data.type";
import { TLeadReportData } from "types/lead-report-data.type";
import { IVTLeadTypes } from "types/ivt.type";
const env = runtimeEnv();

export const createLead = async (
  appId: string,
  leadData: Partial<TLeadToBeCreated>,
  reCaptchaToken?: string
): Promise<TCreatedLead[]> => {
  const { data } = await axios.post(
    `${env.REACT_APP_NEW_API_BASE_URL}/leads/ivt`,
    leadData,
    {
      headers: {
        Authorization: appId,
        recaptchaToken: reCaptchaToken
      }
    }
  );

  return data;
};

export const createIncompleteLead = async (
  appId: string,
  incompleteLead: Partial<TIncompleteLead>
): Promise<{ publicUuid: string }> => {
  const { data } = await axios.post(
    `${env.REACT_APP_NEW_API_BASE_URL}/leads/ivt/incomplete-valuation-leads`,
    incompleteLead,
    {
      headers: {
        Authorization: appId
      }
    }
  );

  return data;
};

export const updateLeadValuationAppointmentAvailability = async (
  appId: string,
  leadId: number,
  appointmentData: LeadValuationAppointmentAvailabilityType
): Promise<TCreatedLead> => {
  const { data } = await axios.put(
    `${env.REACT_APP_NEW_API_BASE_URL}/leads/ivt/${leadId}/valuation-appointment-availability`,
    appointmentData,
    {
      headers: {
        Authorization: appId
      }
    }
  );

  return data;
};

export const getLeadComparables = async (
  appId: string,
  leadId: number,
  valuationType: IVTLeadTypes
): Promise<{ data: PropertyDataType[] }> => {
  return await axios.get(
    `${env.REACT_APP_NEW_API_BASE_URL}/leads/ivt/${leadId}/comparables`,
    {
      params: {
        valuationType
      },
      headers: {
        Authorization: appId
      }
    }
  );
};

export const getLeadReport = async (
  appId: string,
  leadId: number
): Promise<{ data: TLeadReportData }> => {
  return await axios.get(
    `${env.REACT_APP_NEW_API_BASE_URL}/leads/ivt/${leadId}/valuation-report`,
    {
      headers: {
        Authorization: appId
      }
    }
  );
};
