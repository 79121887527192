import { PropertyTypeEnum } from "../enums/property-type.enum";

export const PROPERTY_TYPE_OPTIONS: {
  label: string;
  value: PropertyTypeEnum;
}[] = [
  {
    label: "Detached",
    value: PropertyTypeEnum.DETACHED
  },
  {
    label: "Semi-detached",
    value: PropertyTypeEnum.SEMI_DETACHED
  },
  {
    label: "Terraced",
    value: PropertyTypeEnum.TERRACED
  },
  {
    label: "Flat / Maisonette",
    value: PropertyTypeEnum.FLAT_MAISONETTE
  },
  {
    label: "Other",
    value: PropertyTypeEnum.OTHER
  }
];
