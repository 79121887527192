import { Box, BoxProps, Image, useMediaQuery } from "@chakra-ui/react";
import { useAppContext } from "utils/react-hooks/useAppContext";
import React, { useMemo } from "react";

export const FormWidgetLeftBar = () => {
  const [isLargerThan680] = useMediaQuery("(min-width: 680px)");
  const {
    config: { color, logoUrl }
  } = useAppContext();

  const styling: BoxProps = useMemo(
    () => ({
      display: "flex",
      flexDirection: isLargerThan680 ? "row" : "column",
      alignItems: "flex-start",
      flexShrink: 0,
      width: isLargerThan680 ? "150px" : "100%",
      height: isLargerThan680 ? "100%" : "auto",
      py: isLargerThan680 ? "40px" : "12px",
      px: isLargerThan680 ? "24px" : "12px",
      background: color!
    }),
    [isLargerThan680, color]
  );

  const imgStyling = useMemo(
    () => ({
      maxW: isLargerThan680 ? "100%" : "80px",
      borderRadius: "sm"
    }),
    [isLargerThan680]
  );

  return (
    <Box {...styling}>
      {logoUrl && (
        <Image display={"inline-block"} src={logoUrl} {...imgStyling} />
      )}
    </Box>
  );
};
