export const BEDROOM_OPTIONS: { label: string; value: number }[] = [
  {
    label: "Studio",
    value: 0
  },
  {
    label: "1 bed",
    value: 1
  },
  {
    label: "2 bed",
    value: 2
  },
  {
    label: "3 bed",
    value: 3
  },
  {
    label: "4 bed",
    value: 4
  },
  {
    label: "5 bed",
    value: 5
  }
];
