import { Box, Text } from "@chakra-ui/react";
import React from "react";
import { useAppContext } from "utils/react-hooks/useAppContext";
import { Alert } from "../../../../../components/Alert/Alert";
import { WidgetLayoutEnum } from "../../../../../enums/widget-layout.enum";

export const ValuationReportDisclaimer = () => {
  const {
    config: { layout }
  } = useAppContext();

  return (
    <Alert
      textAlign={layout === WidgetLayoutEnum.FULLSCREEN ? "center" : "left"}
      type={"info"}
      message={
        <Box>
          <Text fontSize={["lg", "xl"]} fontWeight={"bold"}>
            Please note, this valuation is an estimate
          </Text>
          <Text fontSize={["sm", "base"]} maxW="3xl" mx="auto">
            This online valuation was generated automatically using Land
            Registry data and property listings data. It doesn't take into
            account any developments made to your property. For a more accurate
            valuation, please book in a valuation.
          </Text>
        </Box>
      }
    ></Alert>
  );
};
