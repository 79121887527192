import { FullAddressType } from "types/full-address.type";

const generateAddress = (fullAddress: Partial<FullAddressType>) => {
  const { addressLine1, addressLine2, addressLine3, postcode } = fullAddress;
  const components = [];
  if (addressLine1) {
    components.push(addressLine1);
  }
  if (addressLine2) {
    components.push(addressLine2);
  }
  if (addressLine3) {
    components.push(addressLine3);
  }
  if (postcode) {
    components.push(postcode.toUpperCase());
  }
  if (components.length) {
    return components.join(", ");
  }
  return "";
};

export default generateAddress;
