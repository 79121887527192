import { Box, Center, Spinner } from "@chakra-ui/react";
import { useAppContext } from "utils/react-hooks/useAppContext";
import { FC, useEffect, useState } from "react";
import { getQuestionnaireOriginLead } from "api/questionnaire-response.api";
import { ApiRequestStatusEnum } from "enums/api-request-status.enum";
import { ErrorMessage } from "../../../components/ErrorMessage/ErrorMessage";
import { QuestionnaireForm } from "./QuestionnaireForm";
import { TQuestionnaireOriginLead } from "types/lead.type";

interface IProps {
  onSubmit: () => void;
}

export const QuestionnaireFormStep: FC<IProps> = ({ onSubmit }) => {
  const {
    config: { appId, color },
    questionnaireStore: {
      leadPublicUUID,
      setOriginLead,
      questionnairePreviewType
    }
  } = useAppContext();
  const [loadingStatus, setLoadingStatus] = useState(ApiRequestStatusEnum.NONE);
  const [errorMessage, setErrorMessage] = useState("");

  useEffect(() => {
    const fetchData = async () => {
      try {
        setLoadingStatus(ApiRequestStatusEnum.PENDING);
        if (!!leadPublicUUID) {
          const originLead = await getQuestionnaireOriginLead(
            appId,
            leadPublicUUID
          );
          setOriginLead(originLead);
          setLoadingStatus(ApiRequestStatusEnum.SUCCESS);
        } else if (!!questionnairePreviewType) {
          setOriginLead({
            type: questionnairePreviewType
          } as TQuestionnaireOriginLead);
          setLoadingStatus(ApiRequestStatusEnum.SUCCESS);
        } else {
          setLoadingStatus(ApiRequestStatusEnum.ERROR);
        }
      } catch (e) {
        setErrorMessage(e.message);
        setLoadingStatus(ApiRequestStatusEnum.ERROR);
      }
    };

    fetchData();
  }, [appId, leadPublicUUID, setOriginLead, questionnairePreviewType]);

  return (
    <Box width={"100%"} maxW={"680px"}>
      {loadingStatus === ApiRequestStatusEnum.PENDING && (
        <Center
          width={"100%"}
          height={"100%"}
          position={"absolute"}
          top={0}
          left={0}
        >
          <Spinner size={"xl"} color={color || undefined} />
        </Center>
      )}
      {loadingStatus === ApiRequestStatusEnum.ERROR && (
        <Center
          width={"100%"}
          height={"100%"}
          position={"absolute"}
          top={0}
          left={0}
        >
          <ErrorMessage>{errorMessage}</ErrorMessage>
        </Center>
      )}
      {loadingStatus === ApiRequestStatusEnum.SUCCESS && (
        <QuestionnaireForm onSubmit={onSubmit} />
      )}
    </Box>
  );
};
