import {
  Box,
  Modal,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  Text,
  useDisclosure
} from "@chakra-ui/react";
import React, { FC, useCallback, useMemo, useState } from "react";
import { updateLeadValuationAppointmentAvailability } from "api/ivt-lead.api";
import { ValuationAppointmentBookingCalendar } from "./ValuationAppointmentBookingCalendar";
import { useAppContext } from "utils/react-hooks/useAppContext";
import { BrandedButton } from "components/BrandedButton/BrandedButton";
import { TCreatedLead } from "types/lead.type";
import { WidgetLayoutEnum } from "enums/widget-layout.enum";
import { LeadValuationAppointmentAvailabilityType } from "types/lead-valuation-appointment-availability.type";

interface IValuationReportBookingButtonProps {
  lead: TCreatedLead;
}

export const ValuationReportBookingButton: FC<IValuationReportBookingButtonProps> = ({
  lead
}) => {
  const {
    config: { appId, layout },
    ivtStore: { setLead }
  } = useAppContext();
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [selectedAvailability, setSelectedAvailability] = useState<
    LeadValuationAppointmentAvailabilityType
  >({
    hours: []
  });

  const submit = useCallback(async () => {
    setIsSubmitting(true);
    const updatedLead = await updateLeadValuationAppointmentAvailability(
      appId,
      lead.id,
      selectedAvailability
    );
    setLead(updatedLead);
    setIsSubmitting(false);
    onClose();
  }, [onClose, setIsSubmitting, appId, lead.id, selectedAvailability, setLead]);

  const canSubmit = useMemo(() => {
    return !!selectedAvailability.hours.length;
  }, [selectedAvailability]);

  const size = useMemo(() => {
    return layout === WidgetLayoutEnum.POPUP ? "full" : "xl";
  }, [layout]);

  return (
    <>
      <BrandedButton
        mt={4}
        mb={4}
        colorScheme={"primary"}
        size={"lg"}
        onClick={onOpen}
      >
        {!!lead.additionalData.valuation_appointment_availability
          ? "Update valuation appointment times"
          : "Book a valuation appointment"}
      </BrandedButton>
      <Modal isOpen={isOpen} onClose={onClose} size={size} isCentered={true}>
        <ModalOverlay />
        <ModalContent mt={0} mb={0} borderRadius={8}>
          <ModalHeader fontSize={16}>
            Request a valuation appointment
          </ModalHeader>
          <ModalCloseButton />
          <Box bg={"blue.50"} p={4} color={"blue.800"}>
            <Text fontSize={["sm", "base"]} maxW={"3xl"} mx={"auto"}>
              Select as many times as you like and we'll get to you to confirm
              the time
            </Text>
          </Box>
          <ValuationAppointmentBookingCalendar
            hours={
              lead.additionalData.valuation_appointment_availability?.hours.map(
                hour => new Date(hour)
              ) || []
            }
            onChange={setSelectedAvailability}
          />
          <BrandedButton
            colorScheme={"primary"}
            mt={4}
            mb={"14px"}
            ml={4}
            mr={4}
            isDisabled={!canSubmit}
            isLoading={isSubmitting}
            onClick={submit}
            size={"md"}
          >
            Send Request
          </BrandedButton>
        </ModalContent>
      </Modal>
    </>
  );
};
