import React, { FC } from "react";
import { Box, BoxProps } from "@chakra-ui/react";

interface IValuationReportPriceEstimationDotProps {
  justifyContent: BoxProps["justifyContent"];
  size?: "sm" | "md";
}
export const ValuationReportPriceEstimationDot: FC<IValuationReportPriceEstimationDotProps> = ({
  justifyContent,
  size = "sm"
}) => {
  const sizes = {
    sm: 3,
    md: 4
  };

  return (
    <Box
      flex={1}
      display="flex"
      alignItems="center"
      justifyContent={justifyContent}
    >
      <Box
        w={sizes[size]}
        h={sizes[size]}
        bg="primary"
        rounded="full"
        display="inline-block"
        shadow="0 0 0 4px rgba(00,00,00,0.1)"
      />
    </Box>
  );
};
