import { Box } from "@chakra-ui/react";
import React, { useCallback, FC } from "react";
import { ValuationReportPriceEstimationLine } from "./ValuationReportPriceEstimationLine";
import { ValuationReportPriceEstimationValue } from "./ValuationReportPriceEstimationValue";
import { ValuationReportPriceEstimationModeTabs } from "./ValuationReportPriceEstimationModeTabs";
import formatPrice from "utils/formatNumber";
import { TPropertyValueEstimation } from "types/lead.type";
import { ValuationReportModeEnum } from "enums/valuation-report-mode.enum";
import { useAppContext } from "utils/react-hooks/useAppContext";

interface IValuationReportPriceEstimationProps {
  rentEstimation: TPropertyValueEstimation;
  saleEstimation: TPropertyValueEstimation;
}

export const ValuationReportPriceEstimation: FC<IValuationReportPriceEstimationProps> = ({
  rentEstimation,
  saleEstimation
}) => {
  const {
    ivtStore: { valuationReportMode, setValuationReportMode }
  } = useAppContext();

  const handleToggleMode = useCallback(
    (mode: ValuationReportModeEnum) => {
      setValuationReportMode(mode);
    },
    [setValuationReportMode]
  );

  const estimation =
    valuationReportMode === ValuationReportModeEnum.SALE
      ? saleEstimation
      : rentEstimation;

  return (
    <>
      <ValuationReportPriceEstimationModeTabs
        activeMode={valuationReportMode}
        handleToggleMode={handleToggleMode}
      />
      <Box p={[4, 8]} mx="auto" color="primary">
        <ValuationReportPriceEstimationLine />
        <Box display="flex">
          <ValuationReportPriceEstimationValue
            textAlign="left"
            label="Min. value"
            value={formatPrice(estimation.min)}
            fontSize={"xl"}
          />
          <ValuationReportPriceEstimationValue
            textAlign="center"
            label="Est. value"
            value={formatPrice(estimation.est)}
            fontSize={"3xl"}
          />
          <ValuationReportPriceEstimationValue
            textAlign="right"
            label="Max. value"
            value={formatPrice(estimation.max)}
            fontSize={"xl"}
          />
        </Box>
      </Box>
    </>
  );
};
