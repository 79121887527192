import { useAppContext } from "../../../utils/react-hooks/useAppContext";
import { ThankYouScreen } from "../../../components/ThankYouScreen/ThankYouScreen";

export const FormWidgetFinalStep = () => {
  const {
    config: { pageFormConfig }
  } = useAppContext();

  const config = {
    header:
      pageFormConfig.customThankYouPageHeader || "Thank you for your response!",
    body:
      pageFormConfig.customThankYouPageBody ||
      "Thank you for submitting your response! We have recorded your enquiry and one of our agents will be in touch with you soon.",
    ctaLabel: pageFormConfig.customThankYouPageCtaLabel,
    ctaURL: pageFormConfig.customThankYouPageCtaUrl
  };
  return <ThankYouScreen config={config} />;
};
