import React, { FC, useCallback, useEffect, useRef, useState } from "react";
import { Box, HStack, Image, Spinner } from "@chakra-ui/react";
import { getLeadReport } from "api/ivt-lead.api";
import { useAppContext } from "utils/react-hooks/useAppContext";
import { BrandedButton } from "../../../../components/BrandedButton/BrandedButton";

const IMAGE_URL = "images/home-icon.png";
const REFRESH_INTERVAL = 5000;

interface IProps {
  leadId: number;
}

export const ValuationReportDownloadSection: FC<IProps> = ({ leadId }) => {
  const {
    config: { appId }
  } = useAppContext();
  const [reportURL, setReportURL] = useState<string>("");

  const refreshInterval = useRef<ReturnType<typeof setInterval> | undefined>();

  const fetchReport = useCallback(async () => {
    try {
      const { data } = await getLeadReport(appId, leadId);
      setReportURL(data.reportUrl);
    } catch (e) {}
  }, [appId, leadId]);

  useEffect(() => {
    if (refreshInterval.current) clearInterval(refreshInterval.current);

    if (!reportURL) {
      refreshInterval.current = setInterval(fetchReport, REFRESH_INTERVAL);
    }
  }, [reportURL, fetchReport]);

  return (
    <Box px={5} width={"100%"}>
      <Box border={"1px solid"} borderColor={"gray.100"} px={10} py={6}>
        <Box
          fontSize={"sm"}
          flexGrow={1}
          display={"flex"}
          justifyContent={"center"}
          position={"relative"}
        >
          <Box
            position={"absolute"}
            top={0}
            left={0}
            height={"100%"}
            display={"flex"}
            alignItems={"center"}
          >
            <Image src={IMAGE_URL} flexShrink={0} />
          </Box>
          <Box maxWidth={"620px"}>
            <Box fontSize={"md"} fontWeight={"bold"} mb={2}>
              Discover more from your estimated valuation
            </Box>
            <Box mb={6}>
              See more details covering how your property value has been
              determined, and what services we can offer to help sell or let
              your property with our <b>free Valuation Report</b>.
            </Box>
            {!reportURL && (
              <HStack spacing={4} justifyContent={"center"}>
                <Box>
                  <Spinner color={"gray.500"} />
                </Box>
                <Box color={"gray.500"}>
                  Generating your report, please wait a moment...
                </Box>
              </HStack>
            )}
            {!!reportURL && (
              <BrandedButton
                {...{
                  as: "a",
                  href: reportURL
                }}
              >
                Download
              </BrandedButton>
            )}
          </Box>
        </Box>
      </Box>
    </Box>
  );
};
