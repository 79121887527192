import {
  Box,
  FormControl as CFormControl,
  FormControlProps as CFormControlProps,
  Input,
  Switch,
  Textarea
} from "@chakra-ui/react";
import { ErrorMessage } from "components/ErrorMessage/ErrorMessage";
import { FormLabel } from "components/FormLabel/FormLabel";
import React, { useMemo } from "react";
import { INPUT_DEFAULT_STYLE, INPUT_SIZES } from "constants/inputStyles";
import { InputEnum } from "enums/input.enum";
import { Select } from "../Select/Select";
import { SelectOptionType } from "types/select-option.type";
import { useField, useFormikContext } from "formik";
import { MultiValue, SingleValue } from "react-select";
import { FormLabelProps as CFormLabelProps } from "@chakra-ui/form-control/dist/declarations/src/form-label";

export interface IFormControlProps extends CFormControlProps {
  name: string;
  type: InputEnum;
  label?: string;
  labelProps?: CFormLabelProps;
  inputProps?: Record<string, any>;
}

export function FormControl({
  name,
  type,
  label,
  inputProps,
  labelProps,
  ...rest
}: IFormControlProps) {
  const form = useFormikContext<any>();
  const [{ value, onChange, onBlur }] = useField(name);

  const input = useMemo(() => {
    switch (type) {
      case InputEnum.SELECT:
        return (
          <>
            {!!label && (
              <FormLabel htmlFor={name} {...labelProps}>
                {label}
              </FormLabel>
            )}
            <Select
              id={name}
              value={value}
              onChange={(
                uncastOption:
                  | MultiValue<SelectOptionType<any>>
                  | SingleValue<SelectOptionType<any>>
              ) => {
                if (Array.isArray(uncastOption)) {
                  const option = uncastOption as MultiValue<
                    SelectOptionType<any>
                  >;
                  form.setFieldValue(
                    name,
                    option.map(opt => opt.value)
                  );
                } else {
                  const option = uncastOption as SingleValue<
                    SelectOptionType<any>
                  >;
                  form.setFieldValue(
                    name,
                    option === null ? option : option?.value
                  );
                }
              }}
              {...(inputProps as any)}
            />
          </>
        );
      case InputEnum.SWITCH:
        return (
          <Box
            display={"flex"}
            justifyContent={"flex-start"}
            alignItems={"center"}
          >
            <Switch
              id={name}
              isChecked={!!value}
              onChange={event => form.setFieldValue(name, event.target.checked)}
              isInvalid={!!form.errors[name]}
              colorScheme={"leadpro"}
            />
            {!!label && (
              <FormLabel htmlFor={name} mb={0} ml={2} {...labelProps}>
                {label}
              </FormLabel>
            )}
          </Box>
        );
      case InputEnum.TEXT_AREA:
        return (
          <>
            {!!label && (
              <FormLabel htmlFor={name} {...labelProps}>
                {label}
              </FormLabel>
            )}
            <Textarea
              name={name}
              onChange={onChange}
              onBlur={onBlur}
              borderRadius={"md"}
              height={INPUT_SIZES["sm"]}
              value={value as any}
              {...INPUT_DEFAULT_STYLE}
              {...inputProps}
            />
          </>
        );
      case InputEnum.TEXT:
        return (
          <>
            {!!label && (
              <FormLabel htmlFor={name} {...labelProps}>
                {label}
              </FormLabel>
            )}
            <Input
              name={name}
              onChange={onChange}
              onBlur={onBlur}
              borderRadius={"md"}
              height={INPUT_SIZES["sm"]}
              value={value as any}
              {...INPUT_DEFAULT_STYLE}
              {...inputProps}
            />
          </>
        );
    }
  }, [
    inputProps,
    form,
    label,
    name,
    onBlur,
    onChange,
    type,
    value,
    labelProps
  ]);

  return (
    <CFormControl
      isInvalid={!!(form.errors[name] && form.touched[name])}
      {...rest}
    >
      {input}
      {form.errors[name] && form.touched[name] ? (
        <ErrorMessage>{form.errors[name]}</ErrorMessage>
      ) : null}
    </CFormControl>
  );
}
