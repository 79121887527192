import { Box } from "@chakra-ui/react";
import React, { FC } from "react";
import { ValuationReportModeTabsItem } from "./ValuationReportModeTabsItem";
import { ValuationReportModeEnum } from "enums/valuation-report-mode.enum";

interface IValuationReportPriceEstimationModeTabsProps {
  activeMode: ValuationReportModeEnum;
  handleToggleMode: (mode: ValuationReportModeEnum) => void;
}

export const ValuationReportPriceEstimationModeTabs: FC<IValuationReportPriceEstimationModeTabsProps> = ({
  activeMode,
  handleToggleMode
}) => {
  return (
    <Box
      display="flex"
      bg="gray.100"
      rounded="full"
      p={1}
      maxW="xs"
      mx="auto"
      mb={4}
    >
      <ValuationReportModeTabsItem
        isActive={activeMode === ValuationReportModeEnum.SALE}
        onClick={() => handleToggleMode(ValuationReportModeEnum.SALE)}
      >
        Sales
      </ValuationReportModeTabsItem>
      <ValuationReportModeTabsItem
        isActive={activeMode === ValuationReportModeEnum.LETTING}
        onClick={() => handleToggleMode(ValuationReportModeEnum.LETTING)}
      >
        Lettings
      </ValuationReportModeTabsItem>
    </Box>
  );
};
