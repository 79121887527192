import React, { FC } from "react";
import { Box } from "@chakra-ui/react";

interface IFormErrorMessageProps {
  children: React.ReactNode;
}

export const ErrorMessage: FC<IFormErrorMessageProps> = ({ children }) => {
  return (
    <Box
      py={1}
      position={"relative"}
      top={"2px"}
      fontSize={"sm"}
      lineHeight={"normal"}
      px={0}
      rounded={"sm"}
      color={"error"}
      textAlign={"left"}
    >
      {children}
    </Box>
  );
};
