import React, { FC } from "react";
import { Button as CButton, ButtonProps } from "@chakra-ui/react";
import { useTheme } from "@chakra-ui/react";
import { getContrastColor } from "utils/contrast-color";

export interface IBrandedButtonProps extends ButtonProps {}

export const BrandedButton: FC<IBrandedButtonProps> = ({
  children,
  ...rest
}) => {
  const theme = useTheme();

  return (
    <CButton
      px={4}
      fontSize="16px"
      backgroundColor={theme.colors.primary}
      color={getContrastColor(theme.colors.primary)}
      _hover={{}}
      {...rest}
    >
      {children}
    </CButton>
  );
};
