import React, { FC } from "react";
import { Box, Link } from "@chakra-ui/react";

interface IPrivacyPolicyProps {
  link: string;
  text?: string;
}

export const PrivacyPolicy: FC<IPrivacyPolicyProps> = ({
  link,
  text = "By proceeding, you agree to the terms set out in our"
}) => {
  return (
    <Box
      width={"100%"}
      py={4}
      px={8}
      fontSize={"sm"}
      color="leadpro.400"
      textAlign={"center"}
    >
      {text}{" "}
      <Link href={link} target={"_blank"} rel={"noopener noreferrer"}>
        Privacy Policy
      </Link>
      .
    </Box>
  );
};
