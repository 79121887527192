import { Box } from "@chakra-ui/react";
import { FC, useContext, useMemo } from "react";
import { GridContext } from "./Grid";

const calculateWidth = (columns: number) => {
  return 100 / columns + "%";
};

interface IColumn {
  children: React.ReactNode;
}

export const Column: FC<IColumn> = ({ children }) => {
  const { columns, spacing } = useContext(GridContext);

  const width = useMemo(() => {
    if (Array.isArray(columns)) {
      return columns.map(val => calculateWidth(val));
    } else {
      return calculateWidth(columns);
    }
  }, [columns]);

  const style = useMemo(() => {
    const margin = Array.isArray(spacing) ? [...spacing] : [spacing];
    return {
      mb: margin.map(x => {
        return x * 2;
      }),
      px: margin.map(x => {
        return x;
      }),
      width
    };
  }, [spacing, width]);

  return <Box {...style}>{children}</Box>;
};
