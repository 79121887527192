import React, { FC } from "react";
import { ValuationReportBookingButton } from "../../ValuationAppointmentBooking/ValuationReportBookingButton";
import { useAppContext } from "utils/react-hooks/useAppContext";
import { BrandedButton } from "components/BrandedButton/BrandedButton";
import { TCreatedLead } from "types/lead.type";
import { CtaTypeEnum } from "enums/cta-type.enum";
import { LeadResponseType } from "enums/kead-response-type.enum";

interface IValuationReportCTAButtonProps {
  lead: TCreatedLead;
}

export const ValuationReportCTAButton: FC<IValuationReportCTAButtonProps> = ({
  lead
}) => {
  const {
    config: {
      pageIvtConfig: { ctaType, customCtaLabel, customCtaUrl }
    }
  } = useAppContext();

  if (
    ctaType === CtaTypeEnum.BOOKING_CALENDAR &&
    lead.additionalData.response?.type !== LeadResponseType.OutOfArea
  ) {
    return <ValuationReportBookingButton lead={lead} />;
  }

  if (ctaType === CtaTypeEnum.CUSTOM) {
    // eslint-disable-next-line react/jsx-no-target-blank
    return (
      <a rel="noreferrer" href={customCtaUrl || ""} target="_blank">
        <BrandedButton colorScheme="primary" size={"lg"}>
          {customCtaLabel}
        </BrandedButton>
      </a>
    );
  }

  return null;
};
