import { Box, Stack, Text } from "@chakra-ui/react";
import formatPrice from "utils/formatNumber";
import React, { FC } from "react";
import {
  HiOutlineClock,
  HiOutlineLocationMarker,
  HiOutlineCurrencyPound
} from "react-icons/hi";
import { ValuationReportComparableCardAttribute } from "./ValuationReportComparableCardAttribute";
import { ValuationReportComparableCardImages } from "./ValuationReportComparableCardImages";
import { PropertyDataType } from "types/property-data.type";
import { IVTLeadTypes } from "types/ivt.type";
import { LeadTypes } from "@leadpro/forms";

const PRICE_LABEL_SUFFIX: Record<IVTLeadTypes, string> = {
  [LeadTypes.Vendor]: "",
  [LeadTypes.Landlord]: "pcm"
};

const TIME_AGO_LABEL_PREFIX: Record<IVTLeadTypes, string> = {
  [LeadTypes.Vendor]: "sold",
  [LeadTypes.Landlord]: "let"
};

interface IValuationReportComparableCardProps {
  property: PropertyDataType;
  type: IVTLeadTypes;
}

export const ValuationReportComparableCard: FC<IValuationReportComparableCardProps> = ({
  property,
  type
}) => {
  const { beds, address, price, distance, months_ago } = property;

  return (
    <Box
      width={"100%"}
      rounded={"md"}
      shadow={"md"}
      bg={"white"}
      overflow={"hidden"}
    >
      <ValuationReportComparableCardImages property={property} />
      <Box p={[5]}>
        <Box width={"100%"} textAlign={"left"} mb={4}>
          <Text
            fontSize={["lg", "lg"]}
            fontWeight={"medium"}
            lineHeight="none"
            isTruncated
            mb={2}
          >
            {address}
          </Text>
          <Text
            fontSize={["sm"]}
            color={"gray.500"}
            isTruncated
            lineHeight="none"
          >
            {beds} bedrooms
          </Text>
        </Box>
        <Stack spacing={2}>
          {price !== undefined && (
            <ValuationReportComparableCardAttribute
              label={`£${formatPrice(price)} ${PRICE_LABEL_SUFFIX[type]}`}
              icon={HiOutlineCurrencyPound}
            />
          )}
          <ValuationReportComparableCardAttribute
            label={`${distance}m away`}
            icon={HiOutlineLocationMarker}
          />
          <ValuationReportComparableCardAttribute
            label={`Last ${TIME_AGO_LABEL_PREFIX[type]} ${months_ago} month${
              months_ago === 1 ? "" : "s"
            } ago`}
            icon={HiOutlineClock}
          />
        </Stack>
      </Box>
    </Box>
  );
};
