import { NumberedInput } from "../../../FormWidget/components/NumberedInput";
import { FormControl } from "../../../../components/FormControl/FormControl";
import {
  getFormControlPropsFromSchemaItem,
  TFormFromSchemaValues
} from "utils/form-schema.utils";
import React, { FC, useMemo } from "react";
import { FormSchema, getReachableFieldsInOrder } from "@leadpro/forms";
import { QuestionnaireDynamicFormSubmitSection } from "../QuestionnaireDynamicFormSubmitSection";

interface IProps {
  isSubmitting: boolean;
  formSchema: FormSchema;
  formValues: TFormFromSchemaValues;
}

export const QuestionnaireDynamicFormStaticElements: FC<IProps> = ({
  isSubmitting,
  formSchema,
  formValues
}) => {
  const reachableFieldsInOrder = useMemo(
    () => getReachableFieldsInOrder(formSchema, formValues),
    [formSchema, formValues]
  );

  const allFields = formSchema.order;

  return (
    <>
      {allFields.map(fieldKey => {
        const reachableIndex = reachableFieldsInOrder.indexOf(fieldKey);
        return (
          <NumberedInput
            key={fieldKey}
            orderNumber={reachableIndex + 1}
            display={reachableIndex === -1 ? "none" : "inherit"}
          >
            <FormControl
              {...getFormControlPropsFromSchemaItem(formSchema, fieldKey)}
            />
          </NumberedInput>
        );
      })}
      <QuestionnaireDynamicFormSubmitSection
        isSubmitting={isSubmitting}
        formValues={formValues}
      />
    </>
  );
};
