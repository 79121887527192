import axios from "axios";

import {
  TSuggestedAddress,
  TPostcodeAddressSuggestionResponse
} from "types/postcode-address.type";
import runtimeEnv from "@mars/heroku-js-runtime-env";
import { camelCase, mapKeys } from "lodash";
const env = runtimeEnv();

export const getPostcodeAddresses = async (
  appId: string,
  postcode: string
): Promise<TSuggestedAddress[]> => {
  const { data } = await axios.get<TPostcodeAddressSuggestionResponse>(
    `${env.REACT_APP_NEW_API_BASE_URL}/ivt/addresses?postcode=${postcode}`,
    {
      headers: {
        Authorization: appId
      }
    }
  );

  // TODO: REMOVE THIS ONCE IDEAL POSTCODES IS NOT NEEDED
  return data.results.map(address =>
    mapKeys(address, (value, key) => camelCase(key))
  ) as TSuggestedAddress[];
};
