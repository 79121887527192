import { Box, Text } from "@chakra-ui/react";
import { ValuationReportPriceEstimation } from "./ValuationReportPriceEstimation/ValuationReportPriceEstimation";
import { ValuationReportDisclaimer } from "./ValuationReportCTA/ValuationReportDisclaimer";
import { Alert } from "../../../../components/Alert/Alert";
import React, { FC } from "react";
import { TPropertyValueEstimation } from "types/lead.type";

interface IProps {
  isValuationSuccess: boolean;
  rentEstimation: TPropertyValueEstimation;
  saleEstimation: TPropertyValueEstimation;
}

export const ValuationReportDetails: FC<IProps> = ({
  isValuationSuccess,
  rentEstimation,
  saleEstimation
}) => {
  return (
    <Box px={5} width={"100%"}>
      {isValuationSuccess ? (
        <>
          <ValuationReportPriceEstimation
            rentEstimation={rentEstimation}
            saleEstimation={saleEstimation}
          />
          <ValuationReportDisclaimer />
        </>
      ) : (
        <Alert
          type={"warning"}
          message={
            <Text fontSize={["sm", "base"]} maxW="3xl" mx="auto">
              Sorry, but we don't have enough comparable data to provide you the
              instant valuation estimate, but we have sent your details to the
              agent and they'll get in touch to book an in-person valuation
              soon.
            </Text>
          }
        />
      )}
    </Box>
  );
};
