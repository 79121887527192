import {
  BrandedButton,
  IBrandedButtonProps
} from "../../components/BrandedButton/BrandedButton";
import { FC } from "react";
import { useAppContext } from "../../utils/react-hooks/useAppContext";

interface IProps extends IBrandedButtonProps {
  gdprCheckbox: boolean | null;
}
export const GDPRProtectedBrandedButton: FC<IProps> = ({
  gdprCheckbox,
  isDisabled,
  ...rest
}) => {
  const {
    config: { gdprCheckboxEnabled, gdprCheckboxAcceptanceRequired }
  } = useAppContext();

  return (
    <BrandedButton
      {...rest}
      isDisabled={
        isDisabled ||
        (gdprCheckboxEnabled && gdprCheckboxAcceptanceRequired && !gdprCheckbox)
      }
    />
  );
};
