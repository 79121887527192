import { Box, Spinner, Text } from "@chakra-ui/react";
import { Field } from "formik";
import React, { ChangeEvent, useCallback, useState } from "react";
import { ErrorMessage } from "components/ErrorMessage/ErrorMessage";
import { getPostcodeAddresses } from "api/lead-pro.api";
import { INPUT_DEFAULT_STYLE, INPUT_SIZES } from "constants/inputStyles";
import { postcodeRegex } from "utils/regexes";
import { DelayedInput } from "components/FormControl/inputs/DelayedInput";
import { TSuggestedAddress } from "types/postcode-address.type";
import { FormikProps } from "formik/dist/types";
import { PropertyDetailsFormData } from "types/property-valuation-form-data.type";
import { capitalize, chain } from "lodash";
import { ApiRequestStatusEnum } from "enums/api-request-status.enum";

interface IAddressFinderProps {
  appId: string;
  form: FormikProps<PropertyDetailsFormData>;
}

export const AddressFinder = ({ appId, form }: IAddressFinderProps) => {
  const [addresses, setAddresses] = useState<TSuggestedAddress[]>([]);
  const [loadingStatus, setLoadingStatus] = useState(ApiRequestStatusEnum.NONE);

  const { values, setFieldValue, setFieldError } = form;
  const { postcode, addressLine1 } = values;

  const handleFindAddress = useCallback(
    async (event: ChangeEvent<HTMLInputElement>) => {
      const searchValue = event.target.value;

      if (postcodeRegex.test(searchValue)) {
        setFieldError("postcode", undefined);
        setLoadingStatus(ApiRequestStatusEnum.PENDING);
        try {
          const suggestedAddresses = await getPostcodeAddresses(
            appId,
            searchValue
          );

          if (suggestedAddresses.length) {
            setAddresses(suggestedAddresses);
            setLoadingStatus(ApiRequestStatusEnum.SUCCESS);
          } else {
            throw new Error("No addresses found.");
          }
        } catch (e) {
          setFieldError(
            "postcode",
            "No addresses found, please check the postcode you provided."
          );
          setLoadingStatus(ApiRequestStatusEnum.ERROR);
        }
      } else {
        setFieldError("postcode", "Please enter a valid postcode.");
      }
    },
    [setLoadingStatus, setAddresses, setFieldError, appId]
  );

  const resetAddresses = useCallback(async () => {
    setFieldValue("postcode", "");
    setFieldValue("addressLine1", "");
    setFieldValue("addressLine2", "");
    setFieldValue("addressLine3", "");
    setAddresses([]);
  }, [setFieldValue, setAddresses]);

  return (
    <Field name="postcode" id="postcode">
      {({ form }: { form: FormikProps<PropertyDetailsFormData> }) => {
        if (!!addressLine1) {
          return (
            <Box
              lineHeight="none"
              border="1px solid"
              borderColor="gray.200"
              p={4}
              rounded="sm"
            >
              <Box fontWeight="semibold" mb={1}>
                {addressLine1}
              </Box>
              <Box
                fontSize="sm"
                color="gray.500"
                mb={2}
                textTransform="uppercase"
              >
                {postcode}
              </Box>
              <Box
                onClick={resetAddresses}
                fontSize="sm"
                textDecoration="underline"
                cursor="pointer"
              >
                Select a different address
              </Box>
            </Box>
          );
        }

        return (
          <>
            <Box position={"relative"}>
              <DelayedInput
                autoFocus={true}
                placeholder="Enter your postcode"
                onChange={handleFindAddress}
                height={INPUT_SIZES["sm"]}
                {...INPUT_DEFAULT_STYLE}
              />
              {loadingStatus === ApiRequestStatusEnum.PENDING && (
                <Box position={"absolute"} right={"4px"} top={"7px"}>
                  <Spinner />
                </Box>
              )}
              {loadingStatus === ApiRequestStatusEnum.SUCCESS && (
                <Box
                  bg="white"
                  border="1px solid"
                  borderColor="gray.200"
                  mt={1}
                  maxHeight="300px"
                  overflowY="scroll"
                  rounded="md"
                  position="absolute"
                  zIndex={10}
                  width="100%"
                  shadow={"lg"}
                >
                  {addresses.map((address, index) => {
                    const { postcode, line1, line2, line3 } = address;
                    const line1Capitalized = chain(line1 || "")
                      .words()
                      .map(capitalize)
                      .join(" ")
                      .value();
                    const line2Capitalized = chain(line2 || "")
                      .words()
                      .map(capitalize)
                      .join(" ")
                      .value();
                    const line3Capitalized = chain(line3 || "")
                      .words()
                      .map(capitalize)
                      .join(" ")
                      .value();

                    return (
                      <Box
                        key={index}
                        cursor="pointer"
                        borderBottom="1px solid"
                        borderColor="gray.200"
                        fontSize="base"
                        whiteSpace="nowrap"
                        p={3}
                        bg={
                          addressLine1 === line1 ? "green.100" : "transparent"
                        }
                        onClick={() => {
                          setFieldValue("postcode", postcode);
                          setFieldValue("addressLine1", line1);
                          setFieldValue("addressLine2", line2);
                          setFieldValue("addressLine3", line3);
                        }}
                        _hover={{
                          bg: addressLine1 === line1 ? "green.50" : "gray.100"
                        }}
                      >
                        <Text lineHeight="normal" mb={1}>
                          {line1Capitalized}
                        </Text>
                        <Text
                          lineHeight="normal"
                          color="gray.500"
                          fontSize="sm"
                        >
                          {line2Capitalized} {line3Capitalized}
                        </Text>
                      </Box>
                    );
                  })}
                </Box>
              )}
            </Box>
            {form.errors["postcode"] ||
            (form.errors["addressLine1"] && form.touched["addressLine1"]) ? (
              <ErrorMessage>
                {form.errors["postcode"] || form.errors["addressLine1"]}
              </ErrorMessage>
            ) : null}
          </>
        );
      }}
    </Field>
  );
};
