import { Box, Link, Text } from "@chakra-ui/react";
import React, { FC, useMemo } from "react";
import { TLeadResponseProperty } from "types/lead.type";
import { Alert } from "components/Alert/Alert";
import { LeadResponseType } from "enums/kead-response-type.enum";

interface IProps {
  response: TLeadResponseProperty;
}

export const ValuationReportResponseMessage: FC<IProps> = ({ response }) => {
  const alert = useMemo(() => {
    switch (response.type) {
      case LeadResponseType.OutOfArea:
        return (
          <Alert
            type={"warning"}
            message={
              <Text fontSize={["sm", "base"]} maxW="3xl" mx="auto">
                Unfortunately it doesn’t appear we have an office nearby. Please
                contact your{" "}
                <Link
                  color={"blue.500"}
                  href={response.contactUrl}
                  target={"_blank"}
                  rel={"noopener noreferrer"}
                >
                  local office
                </Link>{" "}
                directly.
              </Text>
            }
          />
        );
      default:
        return null;
    }
  }, [response]);

  return (
    <Box px={5} width={"100%"}>
      {alert}
    </Box>
  );
};
