import { Box, BoxProps, HStack } from "@chakra-ui/react";
import { FC } from "react";

interface IProps extends BoxProps {
  orderNumber: number;
}
export const NumberedInput: FC<IProps> = ({
  orderNumber,
  children,
  ...rest
}) => {
  return (
    <HStack spacing={5} alignItems={"flex-start"} width={"100%"} {...rest}>
      <Box
        display={"flex"}
        justifyContent={"flex-start"}
        alignItems={"center"}
        height={"38px"}
      >
        <Box
          width={"38px"}
          height={"100%"}
          fontSize={"xl"}
          color={"leadpro.400"}
          background={"leadpro.50"}
          display={"flex"}
          placeContent={"center"}
          lineHeight={2}
        >
          {orderNumber}
        </Box>
        <Box width={"5px"} height={"100%"} background={"leadpro.200"} />
      </Box>
      <Box flexGrow={1}>{children}</Box>
    </HStack>
  );
};
