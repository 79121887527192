import { Box } from "@chakra-ui/react";
import React, { FC } from "react";

interface IValuationReportComparableCardImageThumbProps {
  id: string;
  index: number;
  activePhoto: number;
  handleSetActivePhoto: (index: number) => void;
}

export const ValuationReportComparableCardImageThumb: FC<IValuationReportComparableCardImageThumbProps> = ({
  id,
  index,
  activePhoto,
  handleSetActivePhoto
}) => {
  return (
    <Box
      cursor="pointer"
      height="50px"
      width="25%"
      onClick={() => handleSetActivePhoto(index)}
      boxShadow={activePhoto === index ? "0 0 0 2px white inset" : undefined}
      backgroundImage={`url(https://www.getagent.co.uk/valuation/property/${id}/photo/${index})`}
      backgroundSize="cover"
      backgroundPosition="center"
      backgroundRepeat="no-repeat"
    />
  );
};
