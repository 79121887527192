import React from "react";
import { AppContext } from "../app-context";

export const useAppContext = () => {
  const context = React.useContext(AppContext);
  if (context === undefined) {
    throw new Error("useAppContext must be used within a AppContext");
  }

  return context;
};
