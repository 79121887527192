import { Box } from "@chakra-ui/react";
import React from "react";

export const QuestionnaireExistingResponseMessage = () => {
  return (
    <>
      <Box color={"leadpro.700"}>
        <Box fontSize={"2xl"} fontWeight={"bold"} mb={2}>
          We've got your answers, thanks!
        </Box>
        <Box maxW={"640px"}>
          It appears that you have already completed this enquiry. Your
          responses have been recorded, thank you.
        </Box>
      </Box>
    </>
  );
};
