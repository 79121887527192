import { CSSReset, ChakraProvider } from "@chakra-ui/react";
import React from "react";
import ReactDOM from "react-dom";
import theme from "themes/default";
import { App } from "./App";
import { initSentry } from "./utils/sentry.utils";

initSentry();

ReactDOM.render(
  <React.StrictMode>
    <ChakraProvider theme={theme}>
      <CSSReset />
      <App />
    </ChakraProvider>
  </React.StrictMode>,
  document.getElementById("root")
);
