import { Box } from "@chakra-ui/react";
import React, { FC, useMemo } from "react";
import { GoogleMap, Marker, useJsApiLoader } from "@react-google-maps/api";
import runtimeEnv from "@mars/heroku-js-runtime-env";
const env = runtimeEnv();

interface IValuationReportMapProps {
  coords: {
    x: number;
    y: number;
  };
}

export const ValuationReportMap: FC<IValuationReportMapProps> = ({
  coords
}) => {
  const { isLoaded } = useJsApiLoader({
    id: "google-map-script",
    googleMapsApiKey: env.REACT_APP_GOOGLE_MAPS_API_KEY
  });

  const center = useMemo(() => {
    return { lat: coords.y, lng: coords.x };
  }, [coords]);

  return (
    <Box bg="gray.100" height={[120, 280]}>
      {isLoaded && (
        <GoogleMap
          mapContainerStyle={{ height: `100%` }}
          center={center}
          zoom={16}
        >
          {/* Child components, such as markers, info windows, etc. */}
          <Marker position={center} />
        </GoogleMap>
      )}
    </Box>
  );
};
