export const INPUT_DEFAULT_STYLE = {
  _placeholder: {
    color: "leadpro.500"
  },
  _hover: {
    borderColor: "leadpro.200",
    bg: "white"
  },
  _invalid: {
    boxShadow: "0 0 0 4px rgba(241, 103, 103, 0.2)",
    borderColor: "error"
  },
  _focus: {
    outline: "none",
    bg: "white",
    boxShadow: "0 0 0 3px rgba(00,00,00,0.1)",
    borderColor: "leadpro.400"
  }
};

export const INPUT_SIZES = {
  sm: "45px"
};
