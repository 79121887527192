import { Box } from "@chakra-ui/react";
import { addHours, format, isEqual } from "date-fns";
import React, { FC } from "react";
import { ValuationAppointmentBookingCalendarHourCell } from "./ValuationAppointmentBookingCalendarHourCell";

const hours = [9, 10, 11, 12, 13, 14, 15, 16, 17];

interface IValuationAppointmentBookingCalendarColumnProps {
  day: Date;
  selectedHours: Date[];
  deselectHour: (hour: Date) => void;
  selectHour: (hour: Date) => void;
  isLastDay: boolean;
}

export const ValuationAppointmentBookingCalendarColumn: FC<IValuationAppointmentBookingCalendarColumnProps> = ({
  day,
  selectedHours,
  deselectHour,
  selectHour,
  isLastDay
}) => {
  return (
    <Box
      borderRight={isLastDay ? "0" : "1px"}
      borderColor="gray.200"
      flexGrow={1}
      textAlign="center"
    >
      <Box borderTop="1px" borderBottom="1px" borderColor="gray.200">
        <Box>{format(day, "EEE")}</Box>
        <Box fontWeight={"semibold"} color="primary">
          {format(day, "d")}
        </Box>
      </Box>
      <Box>
        {hours.map(h => {
          const hour = addHours(day, h);
          const selected = !!selectedHours.find(sh => isEqual(sh, hour));
          return (
            <ValuationAppointmentBookingCalendarHourCell
              key={hour.getTime()}
              selectHour={selectHour}
              deselectHour={deselectHour}
              hour={hour}
              selected={selected}
            />
          );
        })}
      </Box>
    </Box>
  );
};
