import { useAppContext } from "utils/react-hooks/useAppContext";
import { ThankYouScreen } from "../../../components/ThankYouScreen/ThankYouScreen";

export const QuestionnaireWidgetFinalStep = () => {
  const {
    config: { pageQuestionnaireConfig },
    questionnaireStore: { endingPageConfig }
  } = useAppContext();

  const config = {
    header:
      endingPageConfig?.customEndingPageHeader ||
      pageQuestionnaireConfig.customThankYouPageHeader ||
      "Thank you for your response!",
    body:
      endingPageConfig?.customEndingPageBody ||
      pageQuestionnaireConfig.customThankYouPageBody ||
      "Thank you for submitting your response! We have recorded your enquiry and one of our agents will be in touch with you soon.",
    ctaLabel:
      endingPageConfig?.customEndingPageCtaLabel ||
      pageQuestionnaireConfig.customThankYouPageCtaLabel,
    ctaURL:
      endingPageConfig?.customEndingPageCtaUrl ||
      pageQuestionnaireConfig.customThankYouPageCtaUrl
  };

  return <ThankYouScreen config={config} />;
};
