import React, { FC, useCallback, useEffect, useState } from "react";
import theme from "themes/default";
import { ChakraProvider } from "@chakra-ui/react";
import { TAppContextConfig } from "types/app-context.type";
import { AppContext } from "utils/app-context";
import { TCreatedLead, TQuestionnaireOriginLead } from "types/lead.type";
import { WidgetEventsEnum } from "enums/widget-events.enum";
import { PropertyDataType } from "types/property-data.type";
import { ValuationReportModeEnum } from "enums/valuation-report-mode.enum";
import { IVTLeadTypes } from "types/ivt.type";
import { FormCustomEndingPageConfig } from "@leadpro/forms";

interface IConfigLoaderProps {
  children: React.ReactNode;
}

export const ConfigLoader: FC<IConfigLoaderProps> = ({ children }) => {
  const [isLoading, setIsLoading] = useState(true);
  const [config, setConfig] = useState<TAppContextConfig | undefined>();
  const [lead, setLead] = useState<TCreatedLead | undefined>();
  const [endingPageConfig, setEndingPageConfig] = useState<
    FormCustomEndingPageConfig["params"] | undefined
  >();
  const [originLead, setOriginLead] = useState<
    TQuestionnaireOriginLead | undefined
  >();
  const [comparables, setFullComparables] = useState<
    Partial<Record<IVTLeadTypes, PropertyDataType[]>> | undefined
  >();
  const [valuationReportMode, setValuationReportMode] = useState<
    ValuationReportModeEnum
  >(ValuationReportModeEnum.SALE);

  const setComparables = useCallback(
    (partialComparables: Partial<Record<IVTLeadTypes, PropertyDataType[]>>) => {
      setFullComparables({
        ...comparables,
        ...partialComparables
      });
    },
    [comparables]
  );

  const handleMessage = useCallback(event => {
    if (event.data.type === WidgetEventsEnum.WIDGET_CONFIG_READY) {
      const { color } = event.data.value;

      theme.colors.primary = color;

      setConfig(event.data.value);
      setIsLoading(false);
    }
  }, []);

  useEffect(() => {
    window.parent.postMessage(
      {
        type: WidgetEventsEnum.WIDGET_READY
      },
      "*"
    );

    window.addEventListener("message", handleMessage, false);
    return () => window.removeEventListener("message", handleMessage, false);
  }, [handleMessage]);

  if (isLoading || !config) return null;

  return (
    <AppContext.Provider
      value={{
        config,
        ivtStore: {
          lead,
          setLead,
          comparables,
          setComparables,
          valuationReportMode,
          setValuationReportMode
        },
        questionnaireStore: {
          leadPublicUUID: config.questionnaireParams.leadPublicUUID,
          questionnairePreviewType:
            config.questionnaireParams.questionnairePreviewType,
          originLead,
          endingPageConfig,
          setOriginLead,
          setEndingPageConfig
        }
      }}
    >
      <ChakraProvider theme={theme}>{children}</ChakraProvider>
    </AppContext.Provider>
  );
};
