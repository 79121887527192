import { Box, VStack } from "@chakra-ui/react";
import { BrandLogoBox } from "../BrandLogoBox/BrandLogoBox";
import { BrandedButton } from "../BrandedButton/BrandedButton";
import { FC } from "react";
import Markdown from "react-markdown";
import rehypeRaw from "rehype-raw";

interface IProps {
  config: {
    header: string;
    body: string;
    ctaLabel: string | null;
    ctaURL: string | null;
  };
}

export const ThankYouScreen: FC<IProps> = ({ config }) => {
  return (
    <Box width={"100%"} maxW={"724px"}>
      <VStack spacing={10}>
        <BrandLogoBox />
        <Box width={"100%"}>
          <Box
            fontSize={"2xl"}
            fontWeight={700}
            color={"leadpro.700"}
            mb={8}
            textAlign={"center"}
          >
            {config.header}
          </Box>
          <Box textAlign={"center"}>
            <Box fontSize={"md"} fontWeight={400} color={"leadpro.700"}>
              <Markdown rehypePlugins={[rehypeRaw as any]}>
                {config.body}
              </Markdown>
            </Box>
            {!!config.ctaLabel && !!config.ctaURL && (
              <Box mt={20}>
                <BrandedButton
                  isTruncated={true}
                  isFullWidth={true}
                  maxW={"555px"}
                  {...{ as: "a", href: config.ctaURL, target: "_blank" }}
                >
                  {config.ctaLabel}
                </BrandedButton>
              </Box>
            )}
          </Box>
        </Box>
      </VStack>
    </Box>
  );
};
