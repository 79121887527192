import { Box, Heading, VStack } from "@chakra-ui/react";
import React, { FC, useMemo } from "react";
import { ValuationReportComparables } from "./ValuationReportComparables/ValuationReportComparables";
import { ValuationReportLogo } from "./ValuationReportLogo";
import { ValuationReportMap } from "./ValuationReportMap";
import { ValuationReportCTAButton } from "./ValuationReportCTA/ValuationReportCTAButton";
import { TCreatedLead } from "types/lead.type";
import { ValuationReportDetails } from "./ValuationReportDetails";
import { ValuationReportResponseMessage } from "./ValuationReportMessage/ValuationReportResponseMessage";
import { ValuationReportAppointmentAvailabilityMessage } from "./ValuationReportMessage/ValuationReportAppointmentAvailabilityMessage";
import { ValuationReportDownloadSection } from "./ValuationReportDownloadSection";

export const DEFAULT_VALUATION_COORDS = { x: -0.124663, y: 51.4998 };

interface IValuationReportProps {
  lead: TCreatedLead;
  reportDownloadEnabled: boolean;
}

export const ValuationReport: FC<IValuationReportProps> = ({
  lead,
  reportDownloadEnabled
}) => {
  const { additionalData, id: leadId } = lead;

  const {
    sale_estimation: saleEstimation,
    rent_estimation: rentEstimation,
    coords,
    valuation_status,
    response
  } = additionalData;

  const isValuationSuccess = useMemo(() => {
    return valuation_status === "Success";
  }, [valuation_status]);

  const addressLine1 = useMemo(() => {
    return lead.address.split(",")[0];
  }, [lead.address]);

  return (
    <Box
      height={["100%", "100vh"]}
      width={"100%"}
      overflowY="scroll"
      bg="gray.100"
    >
      <Box flex={1} px={1} py={5}>
        <Box mx="auto" position="relative" maxWidth="1000px" textAlign="center">
          <Box px={3}>
            <Box bg="white" shadow="sm" rounded="md" overflow="hidden" mb={3}>
              <ValuationReportMap coords={coords || DEFAULT_VALUATION_COORDS} />
              <ValuationReportLogo />
              <Box
                fontSize="sm"
                textTransform="uppercase"
                fontWeight="normal"
                letterSpacing="widest"
                lineHeight="none"
                opacity={0.5}
                mb={3}
              >
                Valuation report
              </Box>
              <Heading
                fontSize={["2xl", "3xl"]}
                lineHeight="none"
                fontWeight="normal"
                mb={4}
              >
                {addressLine1}
              </Heading>

              <VStack spacing={5} mb={5}>
                <ValuationReportDetails
                  isValuationSuccess={isValuationSuccess}
                  saleEstimation={saleEstimation}
                  rentEstimation={rentEstimation}
                />
                {reportDownloadEnabled && (
                  <ValuationReportDownloadSection leadId={leadId} />
                )}
                {!!response && (
                  <ValuationReportResponseMessage response={response} />
                )}
                {!!lead.additionalData.valuation_appointment_availability && (
                  <ValuationReportAppointmentAvailabilityMessage />
                )}
                <ValuationReportCTAButton lead={lead} />
              </VStack>
            </Box>
            {isValuationSuccess && (
              <ValuationReportComparables leadId={leadId} />
            )}
          </Box>
        </Box>
      </Box>
    </Box>
  );
};
