import { Input, InputProps } from "@chakra-ui/react";
import React, { ChangeEvent, FC, useCallback } from "react";
import { debounce } from "lodash";

const INPUT_DELAY = 600;

export const DelayedInput: FC<InputProps> = ({ onChange, onBlur, ...rest }) => {
  const delayedChange = debounce((event: ChangeEvent<HTMLInputElement>) => {
    onChange?.(event);
  }, INPUT_DELAY);

  const handleInputChange = useCallback(
    (event: ChangeEvent<HTMLInputElement>) => {
      event.persist();
      delayedChange(event);
    },
    [delayedChange]
  );

  return <Input onChange={handleInputChange} {...rest} />;
};
