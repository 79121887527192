import { useAppContext } from "utils/react-hooks/useAppContext";
import React, { FC, KeyboardEvent, useCallback, useMemo } from "react";
import { FormSchema } from "@leadpro/forms";
import {
  getInitialFormFieldValuesFromSchema,
  getReachableData,
  getTruncatedValues,
  TFormFromSchemaValues,
  validateFormFieldValuesFromSchema
} from "utils/form-schema.utils";
import { Form, Formik } from "formik";
import { VStack } from "@chakra-ui/react";
import { FormikConfig, FormikErrors } from "formik/dist/types";
import {
  questionnaireFormResponse,
  questionnairePreviewFormResponse
} from "api/questionnaire-response.api";
import { QuestionnaireEventsEnum } from "enums/questionnaire-events.enum";
import { QuestionnaireDynamicFormSlideshowElements } from "./QuestionnaireDynamicFormElements/QuestionnaireDynamicFormSlideshowElements";
import { QuestionnaireDynamicFormStaticElements } from "./QuestionnaireDynamicFormElements/QuestionnaireDynamicFormStaticElements";

interface IProps {
  formSchema: FormSchema;
  getReCaptchaToken: () => Promise<string | undefined>;
  onSubmit: () => void;
}

export const QuestionnaireDynamicForm: FC<IProps> = ({
  formSchema,
  getReCaptchaToken,
  onSubmit
}) => {
  const {
    config: {
      gdprCheckboxEnabled,
      appId,
      host,
      pageQuestionnaireConfig: { isSlideshowModeEnabled }
    },
    questionnaireStore: {
      leadPublicUUID,
      questionnairePreviewType,
      setEndingPageConfig
    }
  } = useAppContext();

  const initialValues: TFormFromSchemaValues | null = useMemo(
    () => getInitialFormFieldValuesFromSchema(formSchema, gdprCheckboxEnabled),
    [formSchema, gdprCheckboxEnabled]
  );

  const formFieldElements = useCallback(
    (
      isSubmitting: boolean,
      formValues: TFormFromSchemaValues,
      errors: FormikErrors<TFormFromSchemaValues>
    ) => {
      if (isSlideshowModeEnabled) {
        return (
          <QuestionnaireDynamicFormSlideshowElements
            formValues={formValues}
            errors={errors}
            formSchema={formSchema}
            isSubmitting={isSubmitting}
          />
        );
      }

      return (
        <QuestionnaireDynamicFormStaticElements
          formValues={formValues}
          formSchema={formSchema}
          isSubmitting={isSubmitting}
        />
      );
    },
    [formSchema, isSlideshowModeEnabled]
  );

  const validate: FormikConfig<TFormFromSchemaValues>["validate"] = useCallback(
    ({ gdprCheckbox, ...rest }: TFormFromSchemaValues) => {
      const reachableData = getReachableData(rest, formSchema);
      return validateFormFieldValuesFromSchema(reachableData, formSchema);
    },
    [formSchema]
  );

  const handleSubmit = useCallback(
    getReCaptchaToken => async ({
      gdprCheckbox,
      ...rest
    }: TFormFromSchemaValues) => {
      const reCaptchaToken = await getReCaptchaToken();

      if (leadPublicUUID) {
        const endingPageConfig = await questionnaireFormResponse(
          appId,
          {
            leadPublicUuid: leadPublicUUID,
            data: getTruncatedValues(
              getReachableData(rest, formSchema),
              formSchema
            ),
            meta: {
              host,
              gdprCheckbox
            }
          },
          reCaptchaToken
        );
        setEndingPageConfig(endingPageConfig);

        window.parent.postMessage(
          {
            type: QuestionnaireEventsEnum.QUESTIONNAIRE_SUBMITTED
          },
          "*"
        );
      } else if (!!questionnairePreviewType) {
        const endingPageConfig = await questionnairePreviewFormResponse(
          appId,
          {
            previewLeadType: questionnairePreviewType,
            data: getTruncatedValues(
              getReachableData(rest, formSchema),
              formSchema
            ),
            meta: {
              host,
              gdprCheckbox
            }
          },
          reCaptchaToken
        );
        setEndingPageConfig(endingPageConfig);
      }

      onSubmit();
    },
    [
      onSubmit,
      host,
      appId,
      formSchema,
      leadPublicUUID,
      questionnairePreviewType,
      setEndingPageConfig
    ]
  );

  const handleKeyPress = (e: KeyboardEvent) => {
    // Prevent form submission on Enter key press
    if (e.key === "Enter") {
      e.preventDefault();
    }
  };

  return (
    <Formik<TFormFromSchemaValues>
      initialValues={initialValues}
      enableReinitialize={true}
      validate={validate}
      validateOnChange={true}
      validateOnBlur={true}
      onSubmit={handleSubmit(getReCaptchaToken)}
    >
      {({ isSubmitting, values: formValues, errors }) => (
        <Form style={{ width: "100%" }} onKeyDown={handleKeyPress}>
          <VStack spacing={10} alignItems={"flex-start"} width={"100%"}>
            {formFieldElements(isSubmitting, formValues, errors)}
          </VStack>
        </Form>
      )}
    </Formik>
  );
};
