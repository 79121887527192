import { Box, Heading, Image } from "@chakra-ui/react";
import React, { FC } from "react";
import { PrivacyPolicy } from "./PrivacyPolicy";
import { useAppContext } from "utils/react-hooks/useAppContext";
import { WidgetLayoutEnum } from "enums/widget-layout.enum";

interface ILayoutProps {
  children: React.ReactNode;
}
export const Layout: FC<ILayoutProps> = ({ children }) => {
  const {
    config: { layout, logoUrl, privacyPolicyLink }
  } = useAppContext();

  if (layout === WidgetLayoutEnum.POPUP) {
    return (
      <Box flex={1} height="100%">
        <Box bg="primary" px={12} py={8} color="white">
          <Heading fontSize="2xl">Free instant online valuation</Heading>
        </Box>
        <Box px={2} top={-5} position="relative" pb={5}>
          <Box bg="white" rounded="lg" shadow="lg" p={6}>
            {children}
            {!!privacyPolicyLink && <PrivacyPolicy link={privacyPolicyLink} />}
          </Box>
        </Box>
      </Box>
    );
  } else {
    return (
      <Box
        minHeight="100%"
        display="flex"
        alignItems="center"
        id="form-container"
      >
        <Box
          maxW="500px"
          width="100%"
          mx="auto"
          bg="white"
          p={12}
          rounded="lg"
          my={10}
        >
          <Box textAlign="center" mb={4}>
            {logoUrl && (
              <Image display="inline-block" maxW={"140px"} src={logoUrl} />
            )}
          </Box>
          <Heading
            textAlign="center"
            fontSize="2xl"
            fontWeight={500}
            mt={4}
            mb={4}
          >
            Get your instant online valuation
          </Heading>
          {children}
          {!!privacyPolicyLink && <PrivacyPolicy link={privacyPolicyLink} />}
        </Box>
      </Box>
    );
  }
};
