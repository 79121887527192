import { Box, BoxProps, Image } from "@chakra-ui/react";
import { useAppContext } from "utils/react-hooks/useAppContext";
import React, { useMemo } from "react";

export const BrandLogoBox = () => {
  const {
    config: { color, logoUrl }
  } = useAppContext();

  const styling: BoxProps = useMemo(
    () => ({
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      width: "102px",
      height: "102px",
      padding: "12px",
      borderRadius: "md",
      background: color!
    }),
    [color]
  );

  const imgStyling = useMemo(
    () => ({
      maxW: "100%",
      borderRadius: "sm"
    }),
    []
  );

  return (
    <Box {...styling}>
      {logoUrl && (
        <Image display={"inline-block"} src={logoUrl} {...imgStyling} />
      )}
    </Box>
  );
};
