import React, { FC, useCallback, useMemo, useState } from "react";
import { BoxProps, Center } from "@chakra-ui/react";

interface IProps extends BoxProps {
  children: Array<(next: () => void) => React.ReactNode>;
}

export const Wizard: FC<IProps> = ({ children, ...rest }) => {
  const [page, setPage] = useState(0);

  const nextPage = useCallback(() => {
    setPage(Math.min(page + 1, children.length - 1));
  }, [page, children]);

  const activePage = useMemo(() => children[page], [children, page]);

  return (
    <Center height={"100%"} {...rest}>
      {activePage(nextPage)}
    </Center>
  );
};
