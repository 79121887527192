import React, { FC } from "react";
import { Box, Text, Heading, BoxProps } from "@chakra-ui/react";

interface IValuationReportPriceEstimationValueProps {
  label: string;
  value: string;
  textAlign: BoxProps["textAlign"];
  fontSize: BoxProps["fontSize"];
}

export const ValuationReportPriceEstimationValue: FC<IValuationReportPriceEstimationValueProps> = ({
  label,
  value,
  textAlign,
  fontSize
}) => {
  return (
    <Box flex={1} textAlign={textAlign} mt="auto">
      <Text fontSize="md" opacity={0.5} lineHeight="none" mb={2}>
        {label}
      </Text>
      <Heading fontSize={fontSize} lineHeight="none" fontWeight="normal">
        £{value || " ?"}
      </Heading>
    </Box>
  );
};
