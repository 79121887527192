import { Box, VStack } from "@chakra-ui/react";
import { GDPRForm } from "../../../routes/components/GDPRForm";
import { GDPRProtectedBrandedButton } from "../../../routes/components/GDPRProtectedBrandedButton";
import { PrivacyPolicy } from "../../PropertyValuationWidget/components/PrivacyPolicy";
import React, { FC } from "react";
import { TFormFromSchemaValues } from "utils/form-schema.utils";
import { useAppContext } from "utils/react-hooks/useAppContext";

interface IProps {
  isSubmitting: boolean;
  formValues: TFormFromSchemaValues;
}
export const QuestionnaireDynamicFormSubmitSection: FC<IProps> = ({
  isSubmitting,
  formValues
}) => {
  const {
    config: { privacyPolicyLink }
  } = useAppContext();

  return (
    <VStack
      spacing={14}
      marginTop={"44px"}
      paddingLeft={"64px"}
      alignItems={"flex-start"}
      width={"100%"}
    >
      <Box>
        <GDPRForm />
      </Box>
      <Box width={"100%"} textAlign={"center"}>
        <GDPRProtectedBrandedButton
          type={"submit"}
          colorScheme={"primary"}
          isFullWidth={true}
          maxW={"554px"}
          rounded={"full"}
          isDisabled={isSubmitting}
          isLoading={isSubmitting}
          gdprCheckbox={formValues.gdprCheckbox}
        >
          Submit response
        </GDPRProtectedBrandedButton>
        {!!privacyPolicyLink && (
          <PrivacyPolicy
            link={privacyPolicyLink}
            text={
              "By submitting a response, you agree to the terms set out by our"
            }
          />
        )}
      </Box>
    </VStack>
  );
};
