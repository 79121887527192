import { Formik, Form } from "formik";
import React, { FC, useCallback, useMemo } from "react";
import { Box, VStack } from "@chakra-ui/react";
import * as yup from "yup";
import { isValidPhone } from "utils/custom-yup-validators";
import { useAppContext } from "utils/react-hooks/useAppContext";
import { InputEnum } from "enums/input.enum";
import { FormControl } from "components/FormControl/FormControl";
import { PropertyValuationFormDataType } from "types/property-valuation-form-data.type";
import { FormikHelpers } from "formik/dist/types";
import { ReCaptchaGuard } from "../../../components/ReCaptchaGuard/ReCaptchaGuard";
import { GDPRForm } from "routes/components/GDPRForm";
import { GDPRProtectedBrandedButton } from "routes/components/GDPRProtectedBrandedButton";

type TPartialFormValuesWithGDPR = Partial<PropertyValuationFormDataType> & {
  gdprCheckbox: PropertyValuationFormDataType["gdprCheckbox"];
};

const validationSchema = yup.object().shape({
  firstName: yup.string().required("This field is required"),
  lastName: yup.string().required("This field is required"),
  phone: yup
    .string()
    .test("is-valid-phone", "Phone number is not valid", isValidPhone)
    .required("This field is required"),
  email: yup
    .string()
    .email("Please enter a valid email")
    .required("This field is required"),
  gdprCheckbox: yup.boolean().nullable()
});

interface IContactDetailsFormProps {
  values: Partial<PropertyValuationFormDataType>;
  handleSubmit: (
    newValues: Partial<PropertyValuationFormDataType>,
    actions: FormikHelpers<Partial<PropertyValuationFormDataType>>
  ) => void;
}

export const ContactDetailsForm: FC<IContactDetailsFormProps> = ({
  values,
  handleSubmit
}) => {
  const {
    config: { gdprCheckboxEnabled }
  } = useAppContext();

  const initialValues: TPartialFormValuesWithGDPR = useMemo(
    () => ({
      firstName: "",
      lastName: "",
      phone: "",
      email: "",
      gdprCheckbox: gdprCheckboxEnabled ? false : null
    }),
    [gdprCheckboxEnabled]
  );

  const onSubmit = useCallback(
    getReCaptchaToken => async (
      newValues: TPartialFormValuesWithGDPR,
      bag: FormikHelpers<TPartialFormValuesWithGDPR>
    ) => {
      try {
        const reCaptchaToken = await getReCaptchaToken();
        return handleSubmit(
          { ...newValues, reCaptchaToken },
          bag as FormikHelpers<Partial<PropertyValuationFormDataType>>
        );
      } catch (e) {}
    },
    [handleSubmit]
  );

  return (
    <ReCaptchaGuard>
      {getReCaptchaToken => (
        <Formik
          initialValues={{ ...initialValues, ...values }}
          enableReinitialize={true}
          validationSchema={validationSchema}
          validateOnBlur={false}
          onSubmit={onSubmit(getReCaptchaToken)}
        >
          {({ isSubmitting, values: formValues }) => (
            <Form>
              <Box mb={3}>
                <FormControl
                  label={"First name"}
                  name={"firstName"}
                  type={InputEnum.TEXT}
                />
              </Box>
              <Box mb={3}>
                <FormControl
                  label={"Last name"}
                  name={"lastName"}
                  type={InputEnum.TEXT}
                />
              </Box>
              <Box mb={3}>
                <FormControl
                  label={"Email"}
                  name={"email"}
                  type={InputEnum.TEXT}
                />
              </Box>
              <Box mb={3}>
                <FormControl
                  label={"Phone"}
                  name={"phone"}
                  type={InputEnum.TEXT}
                />
              </Box>
              <VStack spacing={3} alignItems={"flex-start"}>
                <GDPRForm />
                <GDPRProtectedBrandedButton
                  type="submit"
                  width="100%"
                  isDisabled={isSubmitting}
                  isLoading={isSubmitting}
                  gdprCheckbox={formValues.gdprCheckbox}
                >
                  Get your valuation
                </GDPRProtectedBrandedButton>
              </VStack>
            </Form>
          )}
        </Formik>
      )}
    </ReCaptchaGuard>
  );
};
